import { v1 as uuidv1 } from "uuid";

export class Galleries {
  constructor() {
    this.signatures = [];
    this.initials = [];
    this.images = [];
  }

  getSignaturesList = () => {
    return this.signatures;
  };

  setSignaturesList = (signatures) => {
    this.signatures = signatures;
  };

  getImagesList = () => {
    return this.images;
  };

  setImagesList = (images) => {
    this.images = images;
  };

  getInitialsList = () => {
    return this.initials;
  };

  setInitialsList = (initials) => {
    this.initials = initials;
  };

  getGalleries = () => {
    return [...this.getSignaturesList(), ...this.getInitialsList()];
  };

  handleCommonGalleriesInterface = (data) => {
    // console.log('* handleCommonGalleriesInterface', data);
    if (data.url.includes('initials/list')) {
      const response = {
        data: this.getInitialsList(),
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('signatures/list')) {
      const response = {
        data: this.getSignaturesList(),
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('signatures/add')) {
      const response = {
        data: { id: uuidv1(), ...data.data },
        result: true,
      };
      this.signatures.push(response.data);
      if (response.data.isDefault) {
        this.signatures = this.signatures.map(item => {
          item.isDefault = item.id === response.data.id;
          return item;
        });
      }
      return Promise.resolve(response);
    }

    if (data.url.includes('initials/add')) {
      const response = {
        data: { id: uuidv1(), ...data.data },
        result: true,
      };
      this.initials.push(response.data);
      if (response.data.isDefault) {
        this.initials = this.initials.map(item => {
          item.isDefault = item.id === response.data.id;
          return item;
        });
      }
      return Promise.resolve(response);
    }

    if (data.url.includes('signatures/delete')) {
      this.signatures = this.signatures.filter(item => item.id !== data.data.id);
      const response = {
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('initials/delete')) {
      this.initials = this.initials.filter(item => item.id !== data.data.id);
      const response = {
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('signatures/set-default')) {
      this.signatures = this.signatures.map(item => {
        item.isDefault = item.id === data.data.id;
        return item;
      });
      const response = {
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('initials/set-default')) {
      this.initials = this.initials.map(item => {
        item.isDefault = item.id === data.data.id;
        return item;
      });
      const response = {
        result: true,
      };
      return Promise.resolve(response);
    }

    if (data.url.includes('signatures/update')) {
      this.signatures = this.signatures.map(item => {
        if (item.id === data.data.id) {
          item = { ...item, ...data.data };
        }
        if (data.data.isDefault) {
          item.isDefault = item.id === data.data.id;
        }
        return item;
      });
      const response = {
        data: { id: data.data.id, ...data.data },
        result: true,
      };
      return Promise.resolve(response);
    }


    if (data.url.includes('initials/update')) {
      this.initials = this.initials.map(item => {
        if (item.id === data.data.id) {
          item = { ...item, ...data.data };
        }
        if (data.data.isDefault) {
          item.isDefault = item.id === data.data.id;
        }
        return item;
      });
      const response = {
        data: { id: data.data.id, ...data.data },
        result: true,
      };
      return Promise.resolve(response);
    }

    const response = {
      message: 'error 226',
      result: false,
    };
    return Promise.reject(response);
  };

  handleImagesOperations = (data) => {
    console.log('* image operation', data);
    if (data.properties && data.properties.type === 'add') {
      const image = {
        id: uuidv1(),
        image: data.properties.image,
        height: data.properties.height,
        width: data.properties.width,
        visible: true
      };
      this.images.push(image);
      data.properties.id = image.id;
      data.visible = true;
      data.initial = false;
      data.enabled = true;
      return data;
    }

    if (data.properties && data.properties.type === 'update') {
      this.images = this.images.filter((img) => {
        return img.id !== data.properties.id;
      });
      const image = {
        id: uuidv1(),
        image: data.properties.image,
        height: data.properties.height,
        width: data.properties.width,
        visible: true
      };
      this.images.push(image);
      data.properties.id = image.id;
      data.visible = true;
      data.initial = false;
      data.enabled = true;
      return data;
    }

    if (data.properties && data.properties.type === 'delete') {
      this.images = this.images.filter((img) => {
        return img.id !== data.properties.id;
      });
      data.visible = false;
      data.initial = false;
      data.enabled = true;
      return data;
    }

    if (data.properties && data.properties.type === 'list') {
      return {
        "id": {},
        "properties": {
          "group": "images",
          "type": "list",
          "images": this.getImagesList(),
          "initial": false,
          "enabled": true
        },
        "confirmed": true
      };
    }
  }
}
