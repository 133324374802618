'use strict';

import EventEmitter from 'events';

export default class Interface extends EventEmitter {
  connect() {
    console.log('ERR:', 'Method connect() not defined (websocket emulator)');
  }

  send() {
    console.log('ERR:', 'Method send() not defined (websocket emulator)');
  }
}
