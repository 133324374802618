import { getApiUrl } from "../constants/config";

const serve = (data, token) => fetch(`${getApiUrl()}/document/convert/ajson/to/pxml`,
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ ...data })
  }).then(response => response.json());
// .then(readAsLookup);

export default serve;
