import React from 'react';

const Loader = () => {
  return (
    <div style={
      {
        "z-index": "2",
        "left": 0,
        "top": 0,
        "width": "100%",
        "height": "100%",
        "position": "fixed",
        "background": "rgba(0, 0, 0, 0.5)"
      }}>
      <div style={{
        "left": "0",
        "top": "0",
        "width": "100%",
        "height": "100%",
        "position": "absolute"
      }}>
        <div style={{
          "left": "50%",
          "position": "absolute",
          "top": "50%",
          "transform": "translate(-50%, -50%)"
        }}><span style={{
          "width": "75px",
          "height": "75px",
          "display": "inline-block",
          "border-width": "2px",
          "border-color": "rgba(9, 9, 9, 0.5)",
          "border-top-color": "#fff",
          "animation": "spin 1s infinite linear",
          "border-radius": "100%",
          "border-style": "solid"
        }}></span></div>
      </div>
    </div>
  );
};

export default Loader;
