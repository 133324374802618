import localforage from 'localforage';
import { v1 as uuidv1 } from 'uuid';

const save = async (key, value) => {
  console.log('* save key', key);
  if (!key) {
    key = uuidv1();
  }
  return localforage.setItem(key, value).then(() => {
    return localforage.getItem(key);
  }).then((value) => {
    return key;
  });
};

const get = async key => {
  return await localforage.getItem(key);
};
// const
export default { save, get };
