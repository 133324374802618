import blobToBase64 from "../utils/blobToBase64";
import { v1 as uuidv1 } from 'uuid';
import cache from "./cache";

const retrieve = async ({ cacheEnabled, key, aJson, urlToAJson, urlToFlat, requestOptions = {} }) => {
  // optionsForFlatRequest = requestOptions.document
  const optionsForFlatRequest = requestOptions;
  const optionsForAJsonRequest = requestOptions;
  if (cacheEnabled && key) {
    const data = await cache.get(key);
    if (data) {
      return data;
    }
    if (!aJson && !urlToAJson && !urlToFlat) {
      throw new Error(`invalid cache id: '${key}' and no retrieve data: aJson, urlToAJson or urlToFlat`);
    }
  }
  const flat = await fetch(urlToFlat, optionsForFlatRequest)
    .then(response => response.blob())
    .then(blob => blobToBase64(blob));

  if (!aJson && urlToAJson) {
    aJson = await fetch(urlToAJson, optionsForAJsonRequest)
      .then(response => response.json());
  }

  if (cacheEnabled) {
    if (!key) {
      key = uuidv1();
    }
    cache.save(key, { aJson, flat });
  }
  return { aJson, flat, key };
};

const saveOrUpdateCache = async (key, data) => {
  return cache.save(key, data);
};

export { retrieve, saveOrUpdateCache };
