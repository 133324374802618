import { converter } from 'jsf-operations';
import { v4 as uuidv4 } from "uuid";

const prepareAutoFields = (fields) => {
  const op2 = converter.operationsFromDocument(fields);
  const operations = [
    {
      "id": {
        "clientId": 0,
        "localId": 6
      },
      "properties": {
        "group": "operations",
        "type": "autofields",
        "initial": false,
        "enabled": true
      },
      "confirmed": true
    },
    ...op2.content.map(item => {
      item.properties.initial = false;
      item.id = { clientId: 1, localId: uuidv4() };
      item.properties.element = item.id;
      return item;
    })
  ];
  return operations;
};

export default prepareAutoFields;
