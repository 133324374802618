'use strict';

import Interface from '../interface';
import { uid, auth } from '../mock/auth';
import features from '../editorConfig/features';
import { converter } from 'jsf-operations';
import getSourceOperation from '../mock/source';
// import { newSource as getSourceOperation } from '../mock/source';
import defaultOperations from "../mock/defaultOperations";
import scenarios from "../mock/scenarios";
import demoWatermarkOperation from '../mock/demo.watermark';
import { mergeDeep } from '../helpers/deepMerge';
import { v4 as uuidv4 } from 'uuid';
import { CONSTRUCTOR_MODE, DEFAULT_MODE, VIEW_MODE } from 'constants/default';

const getAJson = url => fetch(url,{ method: 'GET', mode: 'cors'});

class WebsocketEmulator extends Interface {
  connect(config) {
    setTimeout(() => {
      console.log('* connect WebsocketEmulator');
      this.operationBufer = {};
      this.auth = false;
      this.pagesLocalId = null;
      console.log('!!!!!!! connect', config);
      this.config = config;
      this.localId = uuidv4();
      this.emit('open');
      this.saveIsFine = false;
    }, 0);
  }

  emitOperations(operations) {
    if (!Array.isArray(operations)) { // shit todo rewrite
      operations = [operations];
    }
    this.emit('message', {data: JSON.stringify({operations: operations})});
  }

  getBuffer() {
    return Object.values(this.operationBufer);
  }

  close(payload) {
    console.log('**** close', payload);
    this.emit('close', {id: payload.id});
  }

  getAllContent() {
    return converter.operationsToDocument(this.getBuffer(), { genFullAJson: true, dpi: this.config.properties.contentDPI || 72,  isSortFields: true })['document'];
  }

  mergeFeatureConfig(featureId, featuresConfig, inlineFeaturesConfig) {
    return typeof featuresConfig[featureId] === "undefined"
      ? false
      : (typeof inlineFeaturesConfig !== "undefined" && inlineFeaturesConfig[featureId]) ? inlineFeaturesConfig[featureId].visible : featuresConfig[featureId];
  }

  setOptions(options, featuresConfig, { plan }) {
    console.log('!!!!!!! options options', options);
    const isWatermark = this.config.addDemoWatermark
      ? false
      : this.config.mode === VIEW_MODE
      ? false
      : this.mergeFeatureConfig('attributes.watermark', featuresConfig, options.tools);
    const constructor = this.config.mode === VIEW_MODE
      ? false
      : this.config.mode === 'constructor'
      ? true
      : this.mergeFeatureConfig('tools.constructor', featuresConfig, options.tools);
    console.log('*** 7779 options', options, featuresConfig);
    const data = features({
      mode: this.config.mode,
      constructor: constructor,
      watermark: isWatermark,
      options: options,
    });
    if (options && options.logo) {
      data.properties.logo = { ...data.properties.logo, ...options.logo};
    }
    data.properties.misc = data.properties.misc.map(item => {
      if (typeof featuresConfig[item.id] !== 'undefined') {
        item.visible = !!featuresConfig[item.id];
        if(options && options.tools && options.tools[item.id]) {
          item = { ...item, ...options.tools[item.id] };
        }
        if (item.id === 'common.thumbnails') { // this flag inverted in editor idn why
          item.visible = !item.visible;
        }
        return item;
      }
      return item;
    });
    data.properties.toolbar = data.properties.toolbar.map(item => {
      if (this.config.mode === VIEW_MODE) {
        item.visible = false;
        return item;
      }
      if (typeof featuresConfig[item.id] === 'undefined') {
        item.visible = false;
        return item;
      }
      item.visible = !!featuresConfig[item.id];
      if(options && options.tools && options.tools[item.id]) {
        item = { ...item, ...options.tools[item.id] };
      }
      return item;
    });
    console.log('***** data', data);
    return data;
  }

  getOverlay() {
    if (this.config.mode === VIEW_MODE) {
      return true;
    }
    if (!this.config.options) {
      return false;
    }
    if(!this.config.options.tools) {
      return false;
    }
    return !Object.keys(this.config.options.tools).reduce((acc, item) => {
      if (this.config.options.tools[item].visible && item.indexOf('tools') !== -1) {
        acc = this.config.options.tools[item].visible;
      }
      return  acc;
    }, false);
  }

  send(pkg) {
    if (this.auth && pkg === '{}') {
      this.emit('message', {data: "{}"});
      return;
    }
    pkg = JSON.parse(pkg);
    if (pkg.auth) {
      setTimeout(() => {
        const { urlToFlatDocument, urlToAjson, featureFlags, enforceRequiredFields, baseAPI } = this.config;
        auth.settings.endPoints.baseAPI = baseAPI;
        let assignedAuth = auth;
        assignedAuth.featureFlags = Object.assign({}, auth.featureFlags, featureFlags);
        assignedAuth.project = Object.assign({}, auth.project, featureFlags);

        if (this.auth) { // re auth
          console.log('* more auth for god of auth!');
          assignedAuth.initial = false;
          this.emit('message', {data: JSON.stringify({auth: assignedAuth, uid})});
          return;
        }
        this.emit('message', {data: JSON.stringify({auth: assignedAuth, uid})});
        this.auth = true;
        this.emitOperations(scenarios(this.config.mode === 'constructor',
          this.getOverlay(),
          {
            enforceRequiredFields: this.config.enforceRequiredFields,
            readOnly: this.config.mode === VIEW_MODE,
          })
        );
        console.log('*** here', urlToFlatDocument);
        if (urlToFlatDocument) {
          console .log('****** ababababab', [getSourceOperation(urlToFlatDocument)]);
          this.emitOperations([getSourceOperation(urlToFlatDocument)]);
        }
        if (urlToAjson) {
          this.prepareFields(urlToAjson);
        }
        if (this.config.addDemoWatermark) {
          this.emitOperations(demoWatermarkOperation);
          this.operationBufer[demoWatermarkOperation.properties.element.localId] = demoWatermarkOperation;
        }
        if (this.config.onWsConnect) this.config.onWsConnect();
      }, 0);
    } else if (pkg.operations) {
      setTimeout(() => {
        console.log('* operations from editor', pkg);
        let operations = pkg.operations.map(item => {
          if (item.properties.type === "track") {
            return;
          }
          item.confirmed = true;
          item.properties.allowed = true;
          if (!item.id) {
            item.id = { clientId: 1, localId: /*this.localId++,*/ uuidv4(), };
          }
          if (item.properties.content && item.properties.content.text) {
            this.config.userActed('acted', {message: 'user acted'});
          }
          if (!item.properties.element && item.properties.group === 'tools') {
            item.properties.element = item.id;
          }
          if (item.properties && item.properties.group === 'font') {
            console.log('** font', item);
            this.config.fontRecognize({item: item}).then(payload => {
              this.emit('message', {data: JSON.stringify({operations: payload })});
            });
            return;
          }
          if (item.properties && item.properties.group === 'operations' && item.properties.type && item.properties.type === 'autofields') {
            console.log('** autofields', item);
            this.config.addAutoFields({item: item}).then(payload => {
              payload.map( item => {
                this.operationBufer[item.id.localId] = item;
              });
              this.emit('message', {data: JSON.stringify({operations: payload })});
            });
            return;
          }
          if (item.properties && item.properties.group === 'tools') {
            if (item.properties.type === 'pages') {
              if (this.pagesLocalId === null) {
                this.pagesLocalId = item.properties.element.localId;
              }
              let itemInBuffer = this.operationBufer[this.pagesLocalId];
              this.operationBufer[this.pagesLocalId] = mergeDeep({}, itemInBuffer, item); //
              this.config.rearrange({item}).then(payload => {
                this.emit('message', {data: JSON.stringify({operations: payload })});
              });
              return;
            }
            let itemInBuffer = this.operationBufer[item.properties.element.localId];
            this.operationBufer[item.properties.element.localId] = mergeDeep({}, itemInBuffer, item); //
          }
          if (item.properties && item.properties.group === 'images' /*&& item.properties.type === 'image'*/) {
            let imgOp = this.config.galleries.handleImagesOperations(item);
            console.log('* img operation response', imgOp);
            if (imgOp && imgOp.id && imgOp.id.localId) {
              let itemInBuffer = this.operationBufer[item.id.localId];
              this.operationBufer[imgOp.id.localId] = mergeDeep({}, itemInBuffer, imgOp); //
            }
            return imgOp;
          }
          return item;
        });
        if (operations.length && operations[0]) {
          this.config.onContentUpdate();
          this.emit('message', {data: JSON.stringify({operations: operations})});
        }
      }, 0);
    } else if (pkg.destroy) {
      console.log('!!!!!! destroy pkg', pkg);
      if (pkg.params && (pkg.params.destroyType === 'done'
        || pkg.params.destroyType === 'finalize'
        || (this.saveIsFine && pkg.params.destroyType === 'save'))
      ) {
        setTimeout(() => {
          console.log('****** destroy in timeout');
          const aJson = this.getAllContent();
          this.config.userActed('done', {message: 'destroy'});
          this.config.onDestroy({
            aJson: aJson,
            destroyType: pkg.params.destroyType,
            location: pkg.params.location
          });
          // console.log('!!! -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- !!!!!!! DESTROY OPERATION !!!!!!! -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-', aJson);
        }, 0);
      }
    }

    if (pkg.error) {
      console.log('!!!!!!! pkg.error !!!!!!!!!', pkg.error);
      // this.onError(pkg.error);
    }
  }

  setDocument(urlToFlatDocument, urlToAjson, aJson) {
    console.log('* set document', urlToFlatDocument);
    if (urlToFlatDocument) {
      this.emitOperations([getSourceOperation(urlToFlatDocument)]);
    }
    if (aJson) {
      this.prepareFieldsFromAjson(aJson);
    }
    if (urlToAjson) {
      this.prepareFields(urlToAjson);
    }
  }

  setFeaturesConfig(featuresConfig, options) {

    // todo rewrite this!!!!!
    defaultOperations[2].properties.subType = this.config.mode === VIEW_MODE ? 'view' : 'edit';
    defaultOperations[2].properties.templateContent = this.config.mode === VIEW_MODE ? 'none' : 'full';
    defaultOperations[2].properties.templateFields = this.config.mode === VIEW_MODE ? 'none' : 'full';
    defaultOperations[2].properties.defaults = this.config.mode === VIEW_MODE ? 'none' : 'write';
    defaultOperations[3].properties.fillableWizardState =
      this.mergeFeatureConfig('settings.wizard', featuresConfig, this.config.options.tools)
        ? 'full'
        : 'lite';
    defaultOperations[3].properties["scale"] = "FIT_WIDTH";

    if (this.config.properties) {
      defaultOperations[0].properties.pdfDPI = this.config.properties.pdfDPI || 72;
      defaultOperations[0].properties.contentDPI = this.config.properties.contentDPI || 72;
    }
    console.log('******* defaultOperations', defaultOperations);
    ////
    const op = [
      ...defaultOperations,
      this.setOptions(this.config.options, featuresConfig, options)
    ];
    this.emitOperations(op);
  }

  prepareOperations(data) {
    return ([
      ...data.content.map(item => {
        item.properties.initial = true;
        item.properties.enabled = this.config.mode !== VIEW_MODE;
        item.id = { clientId: 1, localId: uuidv4() };
        item.properties.element = item.id;
        if (item.properties && item.properties.group === 'tools') {
          if(item.properties.content) item.properties.content.owner = '';
          this.operationBufer[item.properties.element.localId] = item;
        }
        return item;
      }),
      {
        "properties": { "group": "editor", "type": "mode", "allowed": true, "subType": "main" },
      },
      ...data.pages
    ]);
  }

  prepareFieldsFromAjson(aJson) {
    return Promise.resolve(aJson)
      .then(converter.operationsFromDocument)
      .then(this.prepareOperations.bind(this))
      .then(this.emitOperations.bind(this));
  }

  prepareFields(urlToAjson) {
    getAJson(urlToAjson)
      .then(response => response.json())
      .then(converter.operationsFromDocument)
      .then(this.prepareOperations.bind(this))
      .then(this.emitOperations.bind(this));
  }

  setSaveIsFine(bool = true) {
    this.saveIsFine = bool;
  }
}

export default WebsocketEmulator;
