import React, { useRef, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { JSFiller } from '@pdffiller/jsf-embedded';
import { Emitter } from './Emitter';
import WebSocketProxy from './WebsocketProxy';
import { CONSTRUCTOR_MODE, DEFAULT_MODE, VIEW_MODE } from 'constants/default';
import { getApiUrl } from 'constants/config';
import file2Base64 from 'utils/fileToBase64';
import prepareDoc from 'api/prepareDocumentFromUrl';
import getDocumentStatus from 'api/getDocumentStatus';
import squash from 'api/makePdf';
import getPdf from 'api/getPdf';
import apiDestroy from 'api/destroy';
import makeDictionary from 'api/makeDictionary';
import setDictionary from 'api/setDictionary';
import pxmlToAJson from 'api/pxmlToAJson';
import aJsonToPxml from 'api/aJsonToPxml';
import addAutoFields from 'api/addAutoFields';
import fontRecognition from 'api/fontRecognition';
import saveToPdffiller from 'api/saveToPdffiller';
import apiLogin from 'api/login';
import Loader from 'react/component/loader';
import appearanceToOptions from './WebsocketEmulator/appearanceConfig';
import { DESTROYED, DOCUMENT_READY, EDITOR_STARTED, WS_CONNECTED } from './constants/events';
import { v1 as uuidv1 } from 'uuid';
import { Galleries } from './Galleries';
import { retrieve, saveOrUpdateCache } from 'cache/index';
import rearrangePages from "./WebsocketEmulator/rearrangePages";
import prepareAutoFields from "./WebsocketEmulator/addAutoFields";
import prepareFontRecognition from "./WebsocketEmulator/fontRecognition";

//todo rewrite
class SDK {
  constructor() {
    console.log('* !!! --- v2 22.12.23');
    this.iframe = React.createRef();
    this.ws = false;
    this.config = {};
    this.mode = DEFAULT_MODE;
    this.saveChanges = true;
    this.editorUrl = '';
    this.featuresConfig = null;
    this.documentId = 0;
    this.shodDeleteDocument = true;
    this.onDestCount = 0;
    this.onReadyCount = 0;
    this.emitter = new Emitter();
    this.saveIsFine = false;
    this.sessionId = uuidv1();
    this.galleries = new Galleries();
    this.cache = {
      allowCaching: false,
      initId: null,
      actualId: null,
      useInitCacheAsActual: false
    };
  }

  on = (eventName, eventHandler) => {
    this.emitter.on(eventName, eventHandler);
  };

  login = async ({ clientId, clientSecret, onLogin }) => {
    // this.emitter.emit('login', {message: 'login start 2'});
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.token = new Promise((resolve, reject) => {
      apiLogin(clientId, clientSecret, this.sessionId).then(response => {
        if (response.status !== 200) {
          this.emitter.error('LOGIN_ERROR',  {message: 'login error', payload: response});
          this.destroy('', false);
          reject(false);
        }
        return response.json();
      }).then(({ accessToken, featuresConfig, editorUrl, paymentPlan, type }) => {
        this.editorUrl = editorUrl;
        this.featuresConfig = featuresConfig;
        this.paymentPlan = paymentPlan;
        this.addDemoWatermark = paymentPlan === 'demo';
        this.type = type;
        if (onLogin) onLogin({ message: "logged in" });
        resolve(accessToken);
      }).catch(e => {
        this.emitter.error('APP_ERROR',  { message: '', payload: e });
      });
    });
  };

  buildDictionary = async (aJson = false) => {
    const token = await this.token;
    aJson = aJson ? aJson : JSON.stringify(this.getAllContent());
    return makeDictionary({aJson}, token).then(result => result.data);
  };

  setDictionary = async (aJson, dictionary) => {
    const token = await this.token;
    return setDictionary({aJson, dictionary}, token).then(result => result.data);
  };

  getEditorData = async () => {
    const token = await this.token;
    await fetch(`${getApiUrl()}/editor/${this.clientId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(response => response.json())
      .then(data => {
        const { fieldsUrl, flatUrl, logoUrl } = data.data.attributes;
        this.config.urlToFlatDocument = flatUrl;
        this.config.urlToAjson = fieldsUrl;
        this.config.urlToLogo = logoUrl;
        this.fromUrlToFlatDoc(flatUrl, fieldsUrl);
      });
  };

  handleDestroyBase = handleDestroy => (system) => {
    const result = {
      isDestroyed: true,
    };
    handleDestroy(system);
    return Promise.resolve(result);
  };

  onWsConnectBase = (onWsConnect) => async payload => {
    console.log('!!! on onWsConnectBase');
    const wse = this.ws.getFirstWs();
    wse.setFeaturesConfig(this.featuresConfig, {
      plan: this.paymentPlan,
    });
    wse.setSaveIsFine(this.saveIsFine);
    this.emitter.state(WS_CONNECTED, {message: `message for ${WS_CONNECTED} state`});
    if (onWsConnect) onWsConnect(payload);
  };

  onReadyBase = (onReady, config) => async payload => {
    console.log('* !!!!!!!!!!!! onReadyBase');
    if (this.iframe.current && this.onReadyCount === 0) {
      const args = {
        viewerId: 1,
        projectId: 2,
        organization: undefined,
        useWebSocketOverBus: true,
        areMobilePlaceholdersVisible: this.config.areMobilePlaceholdersVisible,
        withoutProcessingModal: this.config.withoutProcessingModal,
      };
      console.log('* args', args);
      this.ws = new WebSocketProxy(this.iframe.current.getWebSocketProxy(), {
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        mode: this.mode,
        apiUrl: getApiUrl(),
        userActed: this.emitter.userActed,
        ...config,
        galleries: this.galleries
      });
      if (onReady) {
        setTimeout(() => {
          onReady(payload)
        }, 0);
      }
      try {
        const result = await this.iframe.current.open(args);
      } catch (e) {
        this.emitter.error('APP_ERROR',  { message: '', payload: e });
        console.log('error', e);
      }
    }
    this.onReadyCount++;
  };

  onDestroyBase = onDestroy => async payload => {
    if (this.onDestCount === 0 && this.saveChanges) {
      // const aJson = this.getAllContent();
      this.emitter.state(DESTROYED, {message: 'message for destroyed state'});
      if (onDestroy) onDestroy(payload);
      const token = await this.token;
      apiDestroy(this.clientId, token, { sessionId: this.sessionId, documentId: this.documentId, shodDeleteDocument: this.shodDeleteDocument });
    }
    this.onDestCount++;
  };

  onSaveBase = onSave => async payload => {
    const ajson = this.getAllContent();
    onSave({ ...payload, ajson });
  };

  onInitBase = onInit => payload => {
    if (onInit) onInit(payload);
  };

  onLoginBase = onLogin => payload => {
    if (onLogin) onLogin(payload);
  };

  loadIframe({
    featureFlags,
    appearance,
    options,
    containerId,
    mode,
    onReady,
    onDestroy,
    onOpen,
    onSave,
    handleDestroy,
    onWsConnect,
    urlToFlatDocument,
    urlToAjson,
    enforceRequiredFields = false,
    baseAPI,
    areMobilePlaceholdersVisible = false,
    withoutProcessingModal = true,
  }) {
    this.containerId = containerId;
    this.config.areMobilePlaceholdersVisible = areMobilePlaceholdersVisible;
    this.config.withoutProcessingModal = withoutProcessingModal;
    const container = document.getElementById(containerId);
    this.mode = mode;
    const AUTO_FIELDS_DETECTION = !urlToAjson;
    render(
      <JSFiller
        key={this.key}
        url={this.editorUrl + '?et=l2f&loader=tips&logs.appenders.console.enable=true&sdkRec=1&AUTO_FIELDS_DETECTION=' + AUTO_FIELDS_DETECTION}
        debug
        onReady={this.onReadyBase(onReady,
          {
            urlToFlatDocument,
            urlToAjson,
            onWsConnect: this.onWsConnectBase(onWsConnect),
            onContentUpdate: this.onContentUpdate,
            onDestroy: this.onDestroyBase(onDestroy),
            options: appearanceToOptions(appearance, options, { enforceRequiredFields }),
            featureFlags,
            rearrange: this.onRearrangePages,
            addAutoFields: this.onAddAutoFields,
            fontRecognize: this.onFontRecognize,
            addDemoWatermark: this.addDemoWatermark,
            enforceRequiredFields,
            properties: this.properties,
            baseAPI,
          })
        }
        onOpen={onOpen}
        onSave={this.onSaveBase(onSave)}
        handleCommonGalleriesInterface={this.galleries.handleCommonGalleriesInterface}
        handleDestroy={this.handleDestroyBase(handleDestroy)}
        areMobilePlaceholdersVisible={areMobilePlaceholdersVisible}
        ref={this.iframe}/>,
      container,
    );
  }

  runEditor = async ({
    cache = {
      allowCaching: false,
      initId: '',
      actualId: '',
      useInitCacheAsActual: false,
    },
    featureFlags,
    appearance,
    options,
    mode,
    clientId,
    clientSecret,
    containerId,
    onReady,
    onDestroy,
    onOpen,
    onSave,
    handleDestroy,
    urlToFlatDocument,
    urlToAjson,
    onWsConnect,
    onInit,
    onLogin,
    onFlatReady,
    loaderComponent = false,
    shodDeleteDocument = true,
    saveIsFine = false,
    enforceRequiredFields = false,
    baseAPI = "https://www.pdffiller.com/api_v3",
    properties = {},
    areMobilePlaceholdersVisible = false,
    withoutProcessingModal = true,
  }) => {
    this.config.urlToFlatDocument = urlToFlatDocument;
    this.config.urlToAjson = urlToAjson;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.onFlatReady = onFlatReady;
    this.shodDeleteDocument = shodDeleteDocument;
    this.saveIsFine = saveIsFine;
    this.enforceRequiredFields = enforceRequiredFields;
    this.properties = properties;

    // todo move to appropriate place
    this.cache = { ...this.cache, ...cache };
    this.cache.actualId = this.cache.actualId || uuidv1();

    if (loaderComponent !== false) {
      const container = document.getElementById(containerId);
      if (loaderComponent) {
        render(loaderComponent, container);
      } else {
        render(
          <Loader/>,
          container
        );
      }
    }
    this.onInitBase(onInit)({ message: "init" });
    this.emitter.state(EDITOR_STARTED, {message: 'message for editor started state'});
    this.login({ clientId: this.clientId, clientSecret: this.clientSecret, onLogin: this.onLoginBase(onLogin) });
    const token = await this.token;
    this.loadIframe({
      containerId,
      mode,
      onReady,
      onDestroy,
      onOpen,
      onSave,
      handleDestroy,
      onWsConnect,
      urlToFlatDocument,
      urlToAjson,
      options,
      appearance,
      featureFlags,
      enforceRequiredFields,
      baseAPI,
      areMobilePlaceholdersVisible,
      withoutProcessingModal,
    });
  };
  onFontRecognize = async ({item}) => {
    const token = await this.token;
    const font = await fontRecognition({ ...item.properties, documentId: this.documentId }, token);
    return prepareFontRecognition(font);
  };
  onAddAutoFields = async ({item}) => {
    const token = await this.token;
    const fields = await addAutoFields({url: this.getFlatDocument(), documentId: this.documentId}, token);
    return prepareAutoFields(fields);
  };
  onRearrangePages = async ({item}) => {
    const token = await this.token;
    const rearrangedOperations = await rearrangePages({item, token, documentId: this.documentId, urlToFlatDocument: this.getFlatDocument()});
    if (rearrangedOperations[0]
      && rearrangedOperations[0].properties
      && rearrangedOperations[0].properties.pdf
      && rearrangedOperations[0].properties.pdf.url
    ) {
      this.config.urlToFlatDocument = rearrangedOperations[0].properties.pdf.url;
    }
    return rearrangedOperations;
  };

  done = async (saveChanges = false, redirectUrl = '') => {
    const wse = this.ws.getFirstWs();
    wse.setSaveIsFine();
    const args = { redirectUrl, saveChanges };
    if (this.iframe.current) {
      const result = await this.iframe.current.done(args);
    }
  };

  save = async () => {
    if (this.iframe.current) {
      const result = await this.iframe.current.save();
    }
  };

  destroy = async (redirectUrl = '', saveChanges = true) => {
    if (this.iframe.current) {
      this.saveChanges = saveChanges;
      return await this.iframe.current.destroy({ redirectUrl, saveChanges });
    }
  };

  hasChanges() {
    // todo rewrite
    return !!this.getBuffer().filter(item => !item.properties.initial).length;
  }

  unmount(containerId) {
    this.close();
    const container = document.getElementById(containerId);
    unmountComponentAtNode(container);
  }

  close() {
    if (this.ws) {
      return this.ws.close();
    }
    console.log('** empty ws');
  }

  getBuffer() {
    return this.ws.getBuffer(this.ws.getWsIdsList()[0]);
  }

  getAllContent() {
    return this.ws.getAllContent(this.ws.getWsIdsList()[0]);
  }

  reload() {
    console.log('* reload');
    this.close();
    if (this.iframe.current) {
      this.iframe.current.iframe.current.src = this.iframe.current.iframe.current.src;
      this.onReadyCount = 0;
      this.onDestCount = 0;
    }
  }

  pingDoc = async (documentId) => {
    this.documentId = documentId;
    const token = await this.token;
    return getDocumentStatus(documentId, token)
      .then(({ entities: { document }, result }) => {
        const { status } = document[result];
        if (status === 0
          || status === 1
          || status === 2
          || status === 3
        ) {
          setTimeout(() => this.pingDoc(documentId), 500);
        } else if (status === 4) {
          this.emitter.error('APP_ERROR', { message: 'document preparation fail', payload: {} });
        }
        const { flatUrl, fieldsUrl } = document[result];
        if (flatUrl) {
          return this.fromUrlToFlatDoc(flatUrl, fieldsUrl);
        }
      });
  };

  getFlatDocument = () => {
    return this.config.urlToFlatDocument;
  };

  fromPxml = async (urlToFlatDocument, pxml) => {
    const token = await this.token;
    if (!pxml || pxml.length === 0) {
      return this.fromUrlToFlatDoc(urlToFlatDocument, '', '');
    }
    return pxmlToAJson({pxml}, token).then(({aJson}) => {
      return this.fromUrlToFlatDoc(urlToFlatDocument, '', aJson);
    });
  };

  convertAjsonToPxml = async (aJson) => {
    const token = await this.token;
    return aJsonToPxml({aJson}, token).then(({pxml}) => {
      return pxml;
    });
  };

  fromUrlToFlatDoc = async (urlToFlatDocument, urlToAjson = '', aJson = '') => {
    this.config.urlToFlatDocument = urlToFlatDocument;
    this.config.urlToAjson = urlToAjson;
    let data = { flat: urlToFlatDocument, aJson: aJson, urlToAJson: urlToAjson };
    try {
      data = await retrieve({
        cacheEnabled: this.getAllowCaching(),
        key: this.cache.initId,
        aJson,
        urlToAJson: urlToAjson,
        urlToFlat: urlToFlatDocument,
        requestOptions: {}
      });
      this.config.flat = data.flat;
    } catch (e) {
      this.emitter.error('CACHE_ERROR', { message: 'cache retrieve fail', payload: e});
      console.log('* retrieve error', e);
    }
    if (data && data.key) {
      this.setInitCacheId(data.key);
    }
    const wse = this.ws.getFirstWs();
    wse.setDocument(data.flat, data.urlToAJson, data.aJson);
    if (this.onFlatReady) this.onFlatReady({urlToFlatDocument: this.config.urlToFlatDocument});
    this.emitter.state(DOCUMENT_READY, {message: 'document ready and opening'});
    return Promise.resolve(true);
  };

  fromRawBase64 = async (base64String, filename) => {
    const token = await this.token;
    return prepareDoc(this.clientId, token)({
        name: filename,
        type: filename.split('.').pop(),
        content: base64String.split(',')[1] })
      .then(({ entities: { document }, result }) => { // result = documentId
        return this.pingDoc(result);
      });
  };

  fromRawDocument = async (document) => {
    const token = await this.token;
    return file2Base64(document)
      .then(prepareDoc(this.clientId, token, this.sessionId))
      .then(({ entities: { document }, result }) => { // result = documentId
        return this.pingDoc(result);
      });
  };

  fromUrlToRawDoc = async documentUrl => {
    const token = await this.token;
    prepareDoc(this.clientId, token, this.sessionId)({ documentUrl })
      .then(({ entities: { document }, result }) => { // result = documentId
        this.pingDoc(result);
      });
  };

  fromUrlWithoutExt = async (documentUrl, type) => {
    const token = await this.token;
    prepareDoc(this.clientId, token, this.sessionId)({ documentUrl, type })
      .then(({ entities: { document }, result }) => { // result = documentId
        this.pingDoc(result);
      });
  };

  makePdf = async (urlToFlatDocument = false, aJson = false) => {
    const token = await this.token;
    urlToFlatDocument = urlToFlatDocument ? urlToFlatDocument : this.config.urlToFlatDocument;
    aJson = aJson ? aJson : JSON.stringify(this.getAllContent());
    return squash({ urlToFlatDocument, aJson }, token)
      .then(data => data.data.pdfUrl);
  };

  savePdf = async ({ urlToFlatDocument = false, aJson = false, saveToDesktop = true, filename = '', exportFields = true }) => {
    this.shodDeleteDocument = false;
    const token = await this.token;

    urlToFlatDocument = urlToFlatDocument ? urlToFlatDocument : this.config.urlToFlatDocument;
    aJson = aJson ? aJson : JSON.stringify(this.getAllContent());
    return getPdf({ sessionId: this.sessionId, urlToFlatDocument, aJson, documentId: this.documentId, exportFields }, token)
      .then(blob => {
        if (!saveToDesktop) {
          return new Blob([blob], {type : 'application/pdf'});
        }
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename || `${Date.now()}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
      .catch(() => console.log('oh no!'));

  };

  fromBase64(base64String) {
    this.fromUrlToFlatDoc(base64String);
  }

  loadPreparedDocument = async () => {
    try {
      if (this.getAllowCaching() && this.getInitialCacheId()) {
        const data = await retrieve({
          cacheEnabled: this.getAllowCaching(),
          key: this.getInitialCacheId(),
          requestOptions: {}
        });
        this.fromUrlToFlatDoc(data.flat, null, data.aJson);
      } else {
        this.getEditorData();
      }
    } catch (e) {
      this.emitter.error('CACHE_ERROR', { message: 'cache usage fail', payload: e});
      this.getEditorData();
    }

  };

  loadNewDocument = async (documentUrl) => {
    try {
      if (this.getAllowCaching() && this.getInitialCacheId()) {
        const data = await retrieve({
          cacheEnabled: this.getAllowCaching(),
          key: this.getInitialCacheId(),
          requestOptions: {}
        });
        console.log('* aaaa', data);
        this.fromUrlToFlatDoc(data.flat, null, data.aJson);
      } else {
        this.fromUrlToRawDoc(documentUrl);
      }
    } catch (e) {
      this.emitter.error('CACHE_ERROR', {message: 'cache usage fail', payload: e});
      this.fromUrlToRawDoc(documentUrl);
    }
  };

  savePDF = ({ urlToFlatDocument = false, aJson = false, saveToDesktop = true, filename = '' , onComplete, exportFields = true}) => {
    this.savePdf({ urlToFlatDocument, aJson, saveToDesktop, filename, exportFields }).then(payload => {
      onComplete(payload);
    });
  };

  getPDFBlob = ({onComplete, exportFields = true}) => {
    this.savePdf({saveToDesktop: false, exportFields}).then(blob => {
      onComplete(blob);
    });
  };

  printPDF = ({onComplete}) => {
    this.savePdf({
        saveToDesktop: false
      }).then(function (blob) {
        const url = URL.createObjectURL(blob);
        const frame = document.createElement('iframe');
        frame.src = url;
        frame.id ='pdf-frame';
        document.body.appendChild(frame);
        window.URL.revokeObjectURL(url);
        frame.contentWindow.print();
        setTimeout(() => {
          onComplete({message: 'print'});
        }, 200);

    });
  };

  getSignaturesList = () => {
    return this.galleries.getSignaturesList();
  };

  setSignaturesList = (signatures) => {
    this.galleries.setSignaturesList(signatures);
  };

  getInitialsList = () => {
    return this.galleries.getInitialsList();
  };

  setInitialsList = (initials) => {
    this.galleries.setInitialsList(initials);
  };

  getImagesList = () => {
    return this.galleries.getImagesList();
  };

  setImagesList = (initials) => {
    this.galleries.setImagesList(initials);
  };


  // todo replace and rewrite
  setInitCacheId = cacheId => {
    this.cache.initId = cacheId;
  };

  getAllowCaching = () => {
    return this.cache.allowCaching;
  };

  getInitialCacheId = () => {
    if (!this.getAllowCaching()) { // todo rewrite
      this.emitter.error('CACHE_ERROR', { message: 'Cache is disabled', payload: {} });
      return;
    }
    return this.cache.initId;
  };

  getActualCacheId = () => {
    if (!this.getAllowCaching()) { // todo rewrite
      this.emitter.error('CACHE_ERROR', { message: 'Cache is disabled', payload: {} });
      return;
    }
    if (this.getUseInitCacheAsActual()) {
      this.cache.actualId = this.getInitialCacheId();
    }
    if (!this.cache.actualId) {
      this.cache.actualId = uuidv1();
    }
    return this.cache.actualId;
  };

  getUseInitCacheAsActual = () => {
    return this.cache.useInitCacheAsActual;
  };

  onContentUpdate = () => {
    if (!this.getAllowCaching()) {
      return;
    }
    console.log('* cacheUpdate');
    const data = {
      aJson: this.getAllContent(),
      flat: this.getFlat(),
    };

    const key = this.getUseInitCacheAsActual() ? this.getInitialCacheId() : this.getActualCacheId();
    saveOrUpdateCache(key, data);

  };

  getFlat = () => {
    return this.config.flat;
  };

  setDocumentId = (documentId) => {
    this.documentId = documentId;
  };
}

export { SDK };
