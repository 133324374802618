export default [
  {
    "properties": {
      "group": "document",
      "type": "resolution",
      "pdfDPI": 72,
      "contentDPI": 72,
      "subType": "",
      "enabled": true
    },
    "actionTime": 1581519580552
  }, {
    "properties":
      {
        "group": "document",
        "type": "access",
        "subType": "edit",
        "basicContent": "full",
        "templateContent": "full",
        "templateFields": "full",
        "templateOrigin": "auto",
        "timeout": 60, "location": "",
        "message": "message to show before go to location",
        "roleId": 0,
        "defaults": "write",
        "sigDateStampChangeable": true,
        "restrictSigSubtypes": null,
        "enabled": true
      }, "actionTime": 1581519580552
  }, {
    "properties": {"group": "editor", "type": "mode", "allowed": true, "subType": "init", "enabled": true},
    "actionTime": 1581519580553
  }, {
    "properties": {
      "group": "editor",
      "type": "defaults",
      "content": [{
        "id": "tools.text.*",
        "fontSize": 20,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "fontColor": "000000"
      }, {"id": "tools.checkmark.x", "color": "000000", "width": 15, "height": 15}, {
        "id": "tools.checkmark.v",
        "color": "000000",
        "width": 15,
        "height": 15
      }, {"id": "tools.checkmark.o", "color": "000000", "width": 15, "height": 15}, {
        "id": "tools.signature.curve",
        "color": "000000",
        "smoothing": true,
        "lineWidth": {"desktop": 5, "tablet": 5, "phone": 4}
      }, {
        "id": "tools.signature.text",
        "fontSize": 52,
        "fontFamily": "Jellyka CuttyCupcakes",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top"
      }, {
        "id": "tools.erase.none",
        "lineWidth": 12,
        "fillColor": "ffffff",
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.highlight.none",
        "lineWidth": 12,
        "fillColor": "ffff00",
        "fillAlpha": 0.5,
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.blackout.none",
        "lineWidth": 12,
        "fillColor": "000000",
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.arrow.none",
        "lineWidth": 7,
        "direction": "forward",
        "fillColor": "000000",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.line.none",
        "lineWidth": 3,
        "fillColor": "000000",
        "brushType": "round",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.pen.none",
        "lineWidth": 2,
        "fillColor": "000000",
        "brushType": "round",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.textbox.none",
        "width": 130,
        "height": 150,
        "fontFamily": "Tahoma",
        "fontSize": 14,
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "bgColor": "f7f7f7",
        "borderLineWidth": 2,
        "borderColor": "000000"
      }, {
        "id": "tools.sticky.none",
        "width": 130,
        "height": 150,
        "fontFamily": "Tahoma",
        "fontSize": 14,
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "bgColor": "fbf6a6"
      }, {
        "id": "tools.text.overlying",
        "fontSize": 14,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "fontColor": "000000"
      },
        {
        "id": "tools.attributes.watermark",
        "align": "MC",
        "alpha": 0.5,
        "bold": true,
        "fontColor": "0F0F0F",
        "fontFamily": "Arial",
        "fontSize": 100,
        "italic": false,
        "pagesRange": "-1",
        "rotation": 315,
        "text": "Watermark",
        "underline": false,
        "visible": true
      },
        {
        "id": "tools.attributes.date",
        "align": "TR",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "Arial",
        "fontSize": 13,
        "format": "M/d/yyyy",
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "timeUTC": 0,
        "visible": true
      }, {
        "id": "tools.attributes.numbering",
        "align": "BR",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "Arial",
        "fontSize": 13,
        "text": "Page %current% of %total%",
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "visible": true
      }, {
        "id": "tools.attributes.bates",
        "align": "TL",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "PT Mono",
        "fontSize": 13,
        "digitCount": 6,
        "prefix": "",
        "suffix": "",
        "startNumber": 1,
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "visible": true
      }],
      "template": [{
        "id": "tools.text.*",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true,
        "fontColor": "000000"
      }, {
        "id": "tools.checkmark.x",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {
        "id": "tools.checkmark.v",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {
        "id": "tools.checkmark.o",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {"id": "tools.image", "width": 100, "height": 100, "required": true}, {
        "id": "tools.signature.*",
        "width": 220,
        "height": 30,
        "required": true
      }, {
        "id": "tools.radio.*",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {"id": "tools.signature.initials", "width": 100, "height": 30, "required": true}, {
        "id": "tools.text.number",
        "width": 120,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true
      }, {
        "id": "tools.text.date",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true
      }, {
        "id": "tools.text.dropdown",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true,
        "list": []
      }, {
        "id": "tools.text.formula",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": false
      }],
      "customDictionary": [],
      "useSpellCheck": false,
      "useEmbeddedFonts": true,
      "useSigDateStamp": false,
      "todoListState": "minimized",
      "textFieldViewMode": "stretch",
      "fillableWizardState": "full",
      "scale": "FIT_WIDTH",
      "enabled": true
    }, "actionTime": 1581519580552
  },
];
