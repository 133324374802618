// /document/rearrange/
// import { readAsLookup } from 'json-api-reader/src';

import { getApiUrl } from "../constants/config";

const rearrangeStatus = ({documentId}, token) => fetch(
  `${getApiUrl()}/document/rearrange/${documentId}/status`,
  {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }).then(response => response.json());
// .then(readAsLookup);

export default rearrangeStatus;
