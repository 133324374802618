import { readAsLookup } from 'json-api-reader/src';
import { getApiUrl } from "../constants/config";

const getDocumentStatus = (documentId, token) => fetch(`${getApiUrl()}/document/status/${documentId}`,
  {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }).then(response => response.json())
  .then(readAsLookup);

export default getDocumentStatus;
