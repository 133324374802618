export const operations = [
 {
    "properties": {
      "group": "tools",
      "type": "pages",
      "pages": [{"source": 0, "rotation": 0, "visible": true}],
      "initial": true,
      "element": {"clientId": 1, "localId": 1},
      "enabled": true
    }, "actionTime": 1581519580552, "id": {"clientId": 1, "localId": 1}
  },
  {
    "properties": {"group": "editor", "type": "mode", "allowed": true, "subType": "init", "enabled": true},
    "actionTime": 1581519580553
  }, {
    "properties": {
      "group": "editor",
      "type": "defaults",
      "content": [{
        "id": "tools.text.*",
        "fontSize": 20,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "fontColor": "000000"
      }, {"id": "tools.checkmark.x", "color": "000000", "width": 15, "height": 15}, {
        "id": "tools.checkmark.v",
        "color": "000000",
        "width": 15,
        "height": 15
      }, {"id": "tools.checkmark.o", "color": "000000", "width": 15, "height": 15}, {
        "id": "tools.signature.curve",
        "color": "000000",
        "smoothing": true,
        "lineWidth": {"desktop": 5, "tablet": 5, "phone": 4}
      }, {
        "id": "tools.signature.text",
        "fontSize": 52,
        "fontFamily": "Jellyka CuttyCupcakes",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top"
      }, {
        "id": "tools.erase.none",
        "lineWidth": 12,
        "fillColor": "ffffff",
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.highlight.none",
        "lineWidth": 12,
        "fillColor": "ffff00",
        "fillAlpha": 0.5,
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.blackout.none",
        "lineWidth": 12,
        "fillColor": "000000",
        "brushType": "square",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.arrow.none",
        "lineWidth": 7,
        "direction": "forward",
        "fillColor": "000000",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.line.none",
        "lineWidth": 3,
        "fillColor": "000000",
        "brushType": "round",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.pen.none",
        "lineWidth": 2,
        "fillColor": "000000",
        "brushType": "round",
        "width": 0,
        "height": 0
      }, {
        "id": "tools.textbox.none",
        "width": 130,
        "height": 150,
        "fontFamily": "Tahoma",
        "fontSize": 14,
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "bgColor": "f7f7f7",
        "borderLineWidth": 2,
        "borderColor": "000000"
      }, {
        "id": "tools.sticky.none",
        "width": 130,
        "height": 150,
        "fontFamily": "Tahoma",
        "fontSize": 14,
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "bgColor": "fbf6a6"
      }, {
        "id": "tools.text.overlying",
        "fontSize": 14,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "fontColor": "000000"
      }, {
        "id": "tools.attributes.watermark",
        "align": "MC",
        "alpha": 0.5,
        "bold": true,
        "fontColor": "0F0F0F",
        "fontFamily": "Arial",
        "fontSize": 100,
        "italic": false,
        "pagesRange": "-1",
        "rotation": 315,
        "text": "Watermark",
        "underline": false,
        "visible": true
      }, {
        "id": "tools.attributes.date",
        "align": "TR",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "Arial",
        "fontSize": 13,
        "format": "M/d/yyyy",
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "timeUTC": 0,
        "visible": true
      }, {
        "id": "tools.attributes.numbering",
        "align": "BR",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "Arial",
        "fontSize": 13,
        "text": "Page %current% of %total%",
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "visible": true
      }, {
        "id": "tools.attributes.bates",
        "align": "TL",
        "alpha": 1,
        "bold": true,
        "italic": false,
        "underline": false,
        "fontColor": "000000",
        "fontFamily": "PT Mono",
        "fontSize": 13,
        "digitCount": 6,
        "prefix": "",
        "suffix": "",
        "startNumber": 1,
        "offsetT": 5,
        "offsetB": 5,
        "offsetL": 5,
        "offsetR": 5,
        "pagesRange": "-1",
        "visible": true
      }],
      "template": [{
        "id": "tools.text.*",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "color": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true,
        "fontColor": "000000"
      }, {
        "id": "tools.checkmark.x",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {
        "id": "tools.checkmark.v",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {
        "id": "tools.checkmark.o",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {"id": "tools.image", "width": 100, "height": 100, "required": true}, {
        "id": "tools.signature.*",
        "width": 220,
        "height": 30,
        "required": true
      }, {
        "id": "tools.radio.*",
        "width": 16,
        "height": 16,
        "color": "000000",
        "initial": false,
        "required": true
      }, {"id": "tools.signature.initials", "width": 100, "height": 30, "required": true}, {
        "id": "tools.text.number",
        "width": 120,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true
      }, {
        "id": "tools.text.date",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true
      }, {
        "id": "tools.text.dropdown",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": true,
        "list": []
      }, {
        "id": "tools.text.formula",
        "width": 150,
        "height": 18,
        "fontSize": 14,
        "fontFamily": "Arial",
        "fontColor": "000000",
        "bold": false,
        "italic": false,
        "underline": false,
        "align": "left",
        "valign": "top",
        "initial": "",
        "maxLines": 0,
        "maxChars": 0,
        "arrangement": "none",
        "required": false
      }],
      "customDictionary": [],
      "useSpellCheck": false,
      "useEmbeddedFonts": true,
      "useSigDateStamp": false,
      "todoListState": "minimized",
      "textFieldViewMode": "stretch",
      "fillableWizardState": false,
      "scale": "FIT_WIDTH",
      "enabled": false
    }, "actionTime": 1581519580552
  }];
export const settings = {

};

export const uid = "83606381_1569540027_28_1581519579224";

export const auth = {
  "settings": {
    "ping": {"sendInterval": 5000, "reconnectTimeout": 10000},
    "endPoints": {
      "baseAPI": "https://www.pdffiller.com/api_v3",
      "editorLocale": "https://static.pdffiller.com/frontend/consts.json?type=JSFILLER_LOCALE_2_19&withoutHeader=true",
      "feedbackLocale": "https://static.pdffiller.com/frontend/consts.json?type=FEEDBACK_LOCALE",
      "helpStructure": "https://static-ak.pdffiller.com/frontend/HelpStructure.js",
      "modalsLocale": "https://static.pdffiller.com/frontend/consts.json?type=IMAGE_MANAGER_LOCALE,SIGNATURE_MANAGER_LOCALE,EDITOR_REPORT_PROBLEM",
      "signatureAPI": "https://www.pdffiller.com/api_v3/editor/signature/",
      "tempBinaryAPI": "https://www.pdffiller.com/api_v3/upload/tempBinary/",
      "validatorsCollection": "https://jsfiller-cdn.pdffiller.com/jsfiller/validators/2.19/collection.json",
      "appKey": "Node_GslkioQb54Va*3"
    },
    "tempStorageUrl": "http://www.ppf.int/api_v3",
    "staticUrl": "https://static.pdffiller.com/frontend"
  },
  "clientId": 2,
  "launch": "editor",
  "confirmedOps": 0,
  "featureFlags": {
    "sdkMode": true,
    "disableIdleTimer": true,
    "replace_gtm": true,
    "allowFrontendExperiments": false,
    "autoformatFieldsOnInput": true,
    "clickablePageOf": false,
    "disableFieldAutofocus": false,
    "displayFieldSettingsIcon": false,
    "featureChangeFieldType": true,
    "hidePointerInFullWizard": true,
    "hideThumbnails": true,
    "improveDropdownAlternativeChoice": true,
    "improveOldSSDontShowSubModal": false,
    "improveOldSSHideDoneButton": false,
    "improveOldSSHideThumbnailsForCS": false,
    "newFieldsFlow": true,
    "fieldsListInsteadOfTiles": true,
    "oneClickSignatureAndInitials": true,
    "promptConfirmationBeforeExit": false,
    "radioGroupUXImprove": true,
    "resizableFreeTools": true,
    "resizableRectTools": true,
    "resizableTextTool": true,
    "returnOnePageBtn": false,
    "showChatButton": false,
    "showLeavingPopup": true,
    "showNewJsfOnboarding": false,
    "showSelectionTool": false,
    "showSignatureStampV3": true,
    "useBorderedGhost": true,
    "useCommonSignatureInitialsGalleries": true,
    "useContextMenu": true,
    "useNewFeedbackModal": true,
    "useRoles": false,
    "useSeparateVersionEndpoint": false,
    "useTextSuggestions": false,
    "useThumbnailsContent": false,
    "offlineGalleriesMode": false
  },
  "project": {
    "sdkMode": true,
    "disableIdleTimer": true,
    "replace_gtm": true,
    "allowFrontendExperiments": false,
    "autoformatFieldsOnInput": true,
    "clickablePageOf": false,
    "disableFieldAutofocus": false,
    "displayFieldSettingsIcon": false,
    "featureChangeFieldType": true,
    "hidePointerInFullWizard": true,
    "hideThumbnails": true,
    "improveDropdownAlternativeChoice": true,
    "improveOldSSDontShowSubModal": false,
    "improveOldSSHideDoneButton": false,
    "improveOldSSHideThumbnailsForCS": false,
    "newFieldsFlow": true,
    "fieldsListInsteadOfTiles": true,
    "oneClickSignatureAndInitials": true,
    "promptConfirmationBeforeExit": false,
    "radioGroupUXImprove": true,
    "resizableFreeTools": true,
    "resizableRectTools": true,
    "resizableTextTool": true,
    "returnOnePageBtn": false,
    "showChatButton": false,
    "showLeavingPopup": true,
    "showNewJsfOnboarding": false,
    "showSelectionTool": false,
    "showSignatureStampV3": true,
    "useBorderedGhost": true,
    "useCommonSignatureInitialsGalleries": true,
    "useContextMenu": true,
    "useNewFeedbackModal": true,
    "useRoles": false,
    "useSeparateVersionEndpoint": false,
    "useTextSuggestions": false,
    "useThumbnailsContent": false,
    "offlineGalleriesMode": false,
    "id": 1,
    "modeId": "A1",
    "modeLabel": "standard",
    "name": "name.pdf",
    "owner": {"id": 1, "name": "", "email": ""},
    "viewer": {
      "id": 1,
      "name": "",
      "email": "",
      "avatar": "",
      "modeId": "A1",
      "modeLabel": "standard",
      "loggedIn": true,
      "paid": 156,
      "sourceType": "NEW_UPLOADER",
      "formId": 0
    },
    "integration": null,
    "mapping": {"type": null, "mode": null, "demo": null},
    "exitExperimentUrl": "",
    "useProductTour": true,
    "debug": {
      "projectMode": "standard",
      "projectAccess": "mode_edit",
      "fillableAccess": "fillable_not_available",
      "toolsAccess": "tools_all"
    },
  },
  "initial": true
};


export default {
  "operations": [
    {
      "properties": {
        "group": "document",
        "type": "resolution",
        "pdfDPI": 72,
        "contentDPI": 96,
        "subType": "",
        "enabled": true
      },
      "actionTime": 1581519580552
    },
    {
      "properties":
        {
          "group": "document",
          "type": "access",
          "subType": "edit",
          "basicContent": "full",
          "templateContent": "full",
          "templateFields": "full",
          "templateOrigin": "auto",
          "timeout": 60, "location": "",
          "message": "message to show before go to location",
          "roleId": 0,
          "defaults": "write",
          "sigDateStampChangeable": true,
          "restrictSigSubtypes": null,
          "enabled": true
        }, "actionTime": 1581519580552
    }, {
      "properties": {
        "group": "document",
        "type": "source",
        "pdf": {
          "status": "FINISHED",
          "url": "https://fileservice.pdffillers.com/secure/VgTQx964mtmjaaB6GdiVwwUmzn2xvZG4TUKqdhYplECDtU4JXFJuBa1iP1dDXBMTFgoACFr5Zmn6vMRURTm3N8pnv5u6L2wMNJ0aX41IrR0B?signature=a0bc8c5eb410bb4bdaf7ee57842ab56f61be10ce&key=B9EC5C79D55AD1916518&expires=1581605979&crypt_key=VZQzGREDMWuQfbi2A7xbvB8TidqUQR74deXwLD0pjlmMd1uKBrDx79fcW4wXTO__",
          "errorLocation": "https://dev3.pdffiller.com/en/error-pdf-converting/1569540027.htm",
          "errorCode": 0,
          "version": "1"
        },
        "swf": {
          "status": "FINISHED",
          "url": "https://fileservice.pdffillers.com/secure/MHqJ0-3gi74aMggMvdG_gvbxhnNqdb6CXLIIMpIZQD8bwI0vpAqsNsJGa8JV9v5fPKFQUuQnQyuqOE6KjYQb4ydMWw3z?signature=467150baaf938647b647fcec810b272c084dd239&key=B9EC5C79D55AD1916518&expires=1581519879&crypt_key=VZQzGREDMWuQfbi2A7xbvB8TidqUQR74deXwLD0pjlmMd1uKBrDx79fcW4wXTO__",
          "errorLocation": "https://dev3.pdffiller.com/en/error-progress-form/1569540027.htm",
          "errorCode": 0
        },
        "enabled": true
      }, "actionTime": 1581519580552
    }, {
      "properties": {
        "group": "tools",
        "type": "pages",
        "pages": [{"source": 0, "rotation": 0, "visible": true}],
        "initial": true,
        "element": {"clientId": 1, "localId": 1},
        "enabled": true
      }, "actionTime": 1581519580552, "id": {"clientId": 1, "localId": 1}
    }, {
      "properties": {
        "group": "editor",
        "type": "features",
        "toolbar": [
          {"id": "tools.text.none", "label": "Text", "visible": true}, {
          "id": "tools.checkmark.x",
          "label": "Cross",
          "visible": true
        }, {"id": "tools.checkmark.v", "label": "Check", "visible": true}, {
          "id": "tools.checkmark.o",
          "label": "Circle",
          "visible": true
        }, {"id": "tools.signature.*", "label": "Sign", "visible": true}, {
          "id": "tools.text.date",
          "label": "Date",
          "visible": true
        }, {"id": "tools.image.none", "label": "Image", "visible": true}, {
          "id": "tools.erase.none",
          "label": "Erase",
          "visible": true
        }, {"id": "tools.highlight.none", "label": "Highlight", "visible": true}, {
          "id": "tools.blackout.none",
          "label": "Blackout",
          "visible": true
        }, {"id": "tools.textbox.none", "label": "Text Box", "visible": true}, {
          "id": "tools.arrow.none",
          "label": "Arrow",
          "visible": true
        }, {"id": "tools.line.none", "label": "Line", "visible": true}, {
          "id": "tools.pen.none",
          "label": "Draw",
          "visible": true
        }, {"id": "tools.sticky.none", "label": "Sticky", "visible": true}
        ],
        "extrasbar": [
          {"id": "tools.template", "label": "Constructor", "visible": true}, {
          "id": "versions",
          "label": "Versions",
          "visible": true
        }, {"id": "tools.attributes", "label": "Watermark", "visible": true}
        ],
        "misc": [
          {"id": "tools.pages", "label": "Rearrange", "visible": true}, {
          "id": "tools.comments",
          "label": "Comments",
          "visible": true
        }, {"id": "common.thumbnails", "label": "Thumbnails", "visible": true}, {
          "id": "common.help",
          "label": "Help",
          "visible": true
        }, {"id": "common.feedback", "label": "Feedback", "visible": true}, {
          "id": "signature_wizard.options",
          "capture": false,
          "draw": true,
          "email": false,
          "label": "",
          "notarize": false,
          "notarizeLocation": "",
          "qr": false,
          "sms": false,
          "type": true,
          "upload": false,
          "visible": true
        }, {"id": "common.search", "label": "Search", "visible": false}, {
          "id": "tools.fakePdfEdit",
          "label": "",
          "visible": true
        }],
        "modebar": [{
          "id": "mode.main",
          "label": "Edit",
          "visible": false,
          "selected": false
        }, {
          "id": "mode.constructor",
          "label": "Add Fields",
          "visible": false,
          "selected": false
        }, {"id": "mode.preview", "label": "Preview", "visible": false, "selected": false}
        ],
        "constructor": [
          {"id": "control.breadcrumbs", "visible": true, "label": null}, {
          "id": "button.preview",
          "label": "Preview",
          "visible": true
        }, {"id": "button.cancel", "label": "Cancel", "visible": true}, {
          "id": "button.save",
          "label": "SAVE",
          "visible": true
        }],
        "preview": [
          {"id": "control.breadcrumbs", "visible": true, "label": null}, {
          "id": "button.exit_preview",
          "label": "Preview",
          "visible": true
        }],
        "planPermissions": [
          {"id": "tools.text.none", "allowed": true}, {
          "id": "tools.checkmark.x",
          "allowed": true
        }, {"id": "tools.checkmark.v", "allowed": true}, {
          "id": "tools.checkmark.o",
          "allowed": true
        }, {"id": "tools.text.date", "allowed": true}, {"id": "tools.erase", "allowed": true}, {
          "id": "tools.blackout",
          "allowed": true
        }, {"id": "tools.highlight", "allowed": true}, {"id": "tools.textbox", "allowed": true}, {
          "id": "tools.arrow",
          "allowed": true
        }, {"id": "tools.line", "allowed": true}, {"id": "tools.pen", "allowed": true}, {
          "id": "tools.sticky",
          "allowed": true
        }, {"id": "signatures.add", "allowed": true, "freeLimit": 3}, {
          "id": "images.add",
          "allowed": true
        }, {"id": "versions.apply", "allowed": true}, {
          "id": "tools.*.signature.stamp",
          "allowed": true
        }, {"id": "tools.pages", "allowed": true}, {
          "id": "tools.attributes.*",
          "allowed": true
        }, {"id": "tools.template", "allowed": true}
        ],
        "logo": {
          "urls": {
            "large": "https://static_dev81.pdffillers.com/img/header/logo-pdffiller.svg",
            "small": "https://static_dev81.pdffillers.com/img/header/logo-pdffiller-small.svg"
          }, "visible": true
        },
        "doneButton": {
          "main": {
            "id": "done.choice",
            "label": "DONE",
            "location": "https://dev3.pdffiller.com/en/forms.htm?projectId=1569540027&blank=1"
          },
          "list": [{
            "id": "done.print",
            "label": "Print",
            "location": "https://dev3.pdffiller.com/en/export/print.htm?id=1569540027"
          }, {
            "id": "done.email",
            "label": "Email",
            "location": "https://dev3.pdffiller.com/en/export/send/email.htm?id=1569540027"
          }, {
            "id": "done.sms",
            "label": "SMS",
            "location": "https://dev3.pdffiller.com/en/export/send/sms.htm?id=1569540027"
          }, {
            "id": "done.fax",
            "label": "Fax",
            "location": "https://dev3.pdffiller.com/en/export/fax.htm?id=1569540027"
          }, {
            "id": "done.saveas",
            "label": "Save As",
            "location": "https://dev3.pdffiller.com/en/multi_export/saveAs.htm?ids[]=1569540027"
          }, {"id": "separator"}, {
            "id": "done.share",
            "label": "Share",
            "location": "https://dev3.pdffiller.com/en/export/share.htm?id=1569540027"
          }, {
            "id": "done.l2f",
            "label": "LinkToFill",
            "location": "https://dev3.pdffiller.com/en/link_to_fill/manage.htm?ids%5B0%5D=1569540027"
          }, {
            "id": "done.notarize",
            "label": "Notarize",
            "location": "https://dev3.pdffiller.com/en/notarize.htm?id=1569540027"
          }, {"id": "separator"}, {
            "id": "done.myforms",
            "label": "Go to MyForms",
            "location": "https://dev3.pdffiller.com/en/forms.htm"
          }],
          "visible": true
        },
        "enabled": true
      }, "actionTime": 1581519580552
    }, {
      "properties": {"group": "editor", "type": "mode", "allowed": true, "subType": "init", "enabled": true},
      "actionTime": 1581519580553
    }, {
      "properties": {
        "group": "editor",
        "type": "defaults",
        "content": [{
          "id": "tools.text.*",
          "fontSize": 20,
          "fontFamily": "Arial",
          "color": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "fontColor": "000000"
        }, {"id": "tools.checkmark.x", "color": "000000", "width": 15, "height": 15}, {
          "id": "tools.checkmark.v",
          "color": "000000",
          "width": 15,
          "height": 15
        }, {"id": "tools.checkmark.o", "color": "000000", "width": 15, "height": 15}, {
          "id": "tools.signature.curve",
          "color": "000000",
          "smoothing": true,
          "lineWidth": {"desktop": 5, "tablet": 5, "phone": 4}
        }, {
          "id": "tools.signature.text",
          "fontSize": 52,
          "fontFamily": "Jellyka CuttyCupcakes",
          "color": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top"
        }, {
          "id": "tools.erase.none",
          "lineWidth": 12,
          "fillColor": "ffffff",
          "brushType": "square",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.highlight.none",
          "lineWidth": 12,
          "fillColor": "ffff00",
          "fillAlpha": 0.5,
          "brushType": "square",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.blackout.none",
          "lineWidth": 12,
          "fillColor": "000000",
          "brushType": "square",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.arrow.none",
          "lineWidth": 7,
          "direction": "forward",
          "fillColor": "000000",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.line.none",
          "lineWidth": 3,
          "fillColor": "000000",
          "brushType": "round",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.pen.none",
          "lineWidth": 2,
          "fillColor": "000000",
          "brushType": "round",
          "width": 0,
          "height": 0
        }, {
          "id": "tools.textbox.none",
          "width": 130,
          "height": 150,
          "fontFamily": "Tahoma",
          "fontSize": 14,
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "bgColor": "f7f7f7",
          "borderLineWidth": 2,
          "borderColor": "000000"
        }, {
          "id": "tools.sticky.none",
          "width": 130,
          "height": 150,
          "fontFamily": "Tahoma",
          "fontSize": 14,
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "bgColor": "fbf6a6"
        }, {
          "id": "tools.text.overlying",
          "fontSize": 14,
          "fontFamily": "Arial",
          "color": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "fontColor": "000000"
        }, {
          "id": "tools.attributes.watermark",
          "align": "MC",
          "alpha": 0.5,
          "bold": true,
          "fontColor": "0F0F0F",
          "fontFamily": "Arial",
          "fontSize": 100,
          "italic": false,
          "pagesRange": "-1",
          "rotation": 315,
          "text": "Watermark",
          "underline": false,
          "visible": true
        }, {
          "id": "tools.attributes.date",
          "align": "TR",
          "alpha": 1,
          "bold": true,
          "italic": false,
          "underline": false,
          "fontColor": "000000",
          "fontFamily": "Arial",
          "fontSize": 13,
          "format": "M/d/yyyy",
          "offsetT": 5,
          "offsetB": 5,
          "offsetL": 5,
          "offsetR": 5,
          "pagesRange": "-1",
          "timeUTC": 0,
          "visible": true
        }, {
          "id": "tools.attributes.numbering",
          "align": "BR",
          "alpha": 1,
          "bold": true,
          "italic": false,
          "underline": false,
          "fontColor": "000000",
          "fontFamily": "Arial",
          "fontSize": 13,
          "text": "Page %current% of %total%",
          "offsetT": 5,
          "offsetB": 5,
          "offsetL": 5,
          "offsetR": 5,
          "pagesRange": "-1",
          "visible": true
        }, {
          "id": "tools.attributes.bates",
          "align": "TL",
          "alpha": 1,
          "bold": true,
          "italic": false,
          "underline": false,
          "fontColor": "000000",
          "fontFamily": "PT Mono",
          "fontSize": 13,
          "digitCount": 6,
          "prefix": "",
          "suffix": "",
          "startNumber": 1,
          "offsetT": 5,
          "offsetB": 5,
          "offsetL": 5,
          "offsetR": 5,
          "pagesRange": "-1",
          "visible": true
        }],
        "template": [{
          "id": "tools.text.*",
          "width": 150,
          "height": 18,
          "fontSize": 14,
          "fontFamily": "Arial",
          "color": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "initial": "",
          "maxLines": 0,
          "maxChars": 0,
          "arrangement": "none",
          "required": true,
          "fontColor": "000000"
        }, {
          "id": "tools.checkmark.x",
          "width": 16,
          "height": 16,
          "color": "000000",
          "initial": false,
          "required": true
        }, {
          "id": "tools.checkmark.v",
          "width": 16,
          "height": 16,
          "color": "000000",
          "initial": false,
          "required": true
        }, {
          "id": "tools.checkmark.o",
          "width": 16,
          "height": 16,
          "color": "000000",
          "initial": false,
          "required": true
        }, {"id": "tools.image", "width": 100, "height": 100, "required": true}, {
          "id": "tools.signature.*",
          "width": 220,
          "height": 30,
          "required": true
        }, {
          "id": "tools.radio.*",
          "width": 16,
          "height": 16,
          "color": "000000",
          "initial": false,
          "required": true
        }, {"id": "tools.signature.initials", "width": 100, "height": 30, "required": true}, {
          "id": "tools.text.number",
          "width": 120,
          "height": 18,
          "fontSize": 14,
          "fontFamily": "Arial",
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "initial": "",
          "maxLines": 0,
          "maxChars": 0,
          "arrangement": "none",
          "required": true
        }, {
          "id": "tools.text.date",
          "width": 150,
          "height": 18,
          "fontSize": 14,
          "fontFamily": "Arial",
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "initial": "",
          "maxLines": 0,
          "maxChars": 0,
          "arrangement": "none",
          "required": true
        }, {
          "id": "tools.text.dropdown",
          "width": 150,
          "height": 18,
          "fontSize": 14,
          "fontFamily": "Arial",
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "initial": "",
          "maxLines": 0,
          "maxChars": 0,
          "arrangement": "none",
          "required": true,
          "list": []
        }, {
          "id": "tools.text.formula",
          "width": 150,
          "height": 18,
          "fontSize": 14,
          "fontFamily": "Arial",
          "fontColor": "000000",
          "bold": false,
          "italic": false,
          "underline": false,
          "align": "left",
          "valign": "top",
          "initial": "",
          "maxLines": 0,
          "maxChars": 0,
          "arrangement": "none",
          "required": false
        }],
        "customDictionary": [],
        "useSpellCheck": false,
        "useEmbeddedFonts": true,
        "useSigDateStamp": false,
        "todoListState": "minimized",
        "textFieldViewMode": "stretch",
        "fillableWizardState": "full",
        "scale": "FIT_WIDTH",
        "enabled": true
      }, "actionTime": 1581519580552
    }],
  "sessionHash": "647a462c00c54a2a985ad21372350d69",
  "reconnect": false,
  "auth": {
    "settings": {
      "ping": {"sendInterval": 5000, "reconnectTimeout": 10000},
      "endPoints": {
        "baseAPI": "https://dev3.pdffiller.com/api_v3",
        "tempBinaryAPI": "https://dev3.pdffiller.com/api_v3/upload/tempBinary/",
        "signatureAPI": "https://dev3.pdffiller.com/api_v3/editor/signature/",
        "helpStructure": "https://front-dev.pdffiller.com/dev3/HelpStructure.js",
        "feedbackLocale": "https://front-dev.pdffiller.com/dev3/consts.json?type=FEEDBACK_LOCALE",
        "modalsLocale": "https://front-dev.pdffiller.com/dev3/consts.json?type=IMAGE_MANAGER_LOCALE,SIGNATURE_MANAGER_LOCALE,EDITOR_REPORT_PROBLEM",
        "editorLocale": "https://front-dev.pdffiller.com/dev3/consts.json?type=JSFILLER_LOCALE_2_19&withoutHeader=true",
        "validatorsCollection": "https://shared.pdffiller.com/jsfiller/validators/2.19/collection.json",
        "appKey": "Node_GslkioQb54Va*3"
      },
      "tempStorageUrl": "https://dev3.pdffiller.com/api_v3",
      "staticUrl": "https://front-dev.pdffiller.com/dev3"
    },
    "clientId": 2,
    "launch": "editor",
    "confirmedOps": 0,
    "project": {
      "id": 1569540027,
      "modeId": "A1",
      "modeLabel": "standard",
      "name": "366(3).pdf",
      "owner": {"id": 83606381, "name": "siryack vicktor+321", "email": "siryack.vicktor+321@pdffiller.team"},
      "viewer": {
        "id": 83606381,
        "name": "siryack vicktor+321",
        "email": "siryack.vicktor+321@pdffiller.team",
        "avatar": "",
        "modeId": "A1",
        "modeLabel": "standard",
        "loggedIn": true,
        "paid": 156,
        "sourceType": "NEW_UPLOADER",
        "formId": 0
      },
      "integration": null,
      "mapping": {"type": null, "mode": null, "demo": null},
      "exitExperimentUrl": "https://dev3.pdffiller.com/en/project/1569540027.htm?call=FLASH&fmode=oops",
      "useProductTour": true,
      "debug": {
        "projectMode": "standard",
        "projectAccess": "mode_edit",
        "fillableAccess": "fillable_not_available",
        "toolsAccess": "tools_all"
      },
      "useNewFeedbackModal": false,
      "showChatButton": false
    },
    "initial": true
  }, "uid": "83606381_1569540027_28_1581519579224"
};
