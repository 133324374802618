export default (url, content = null) => {
  console.log('** !!!getSourceOperation', url);
  return ({
    "properties": {
      "group": "document",
      "type": "source",
      "pdf": {
        "status": "FINISHED",
        "url": url,
        "errorLocation": "https://errorLocation.com",
        "errorCode": 0,
        "version": "1"
      },
      "enabled": true
    }, "actionTime": 1581519580552
  });
}
export const newSource = (url) => {
  // console.log('** !!! new getSourceOperation', url);
  return ({
    "properties": {
      "group": "tools",
        "type": "rearrange_v2",
        "patch": [
        {
          "op": "add",
          "path": "",
          "value": {
            "firstDocumentUuid": "dac375a4-74b7-48b6-a772-88a283fe3701",
            "sources": {
              "8468a927-f63b-4a8d-86ca-65489ffa61f2": {
                "status": "FINISHED",
                "url": url,
                "errorLocation": "https://www.pdffiller-rc.xyz/en/error-pdf-converting/775225568.htm",
                "errorCode": 0,
                "version": "1",
                "defaultPage": 0,
                "pagesModified": false
              }
            },
            "documents": {
              "dac375a4-74b7-48b6-a772-88a283fe3701": {
                "documentUuid": "dac375a4-74b7-48b6-a772-88a283fe3701",
                "projectName": "Constructor(86)",
                "sourceUuid": "8468a927-f63b-4a8d-86ca-65489ffa61f2",
                "next": null
              }
            }
          }
        }
      ],
        "initial": true,
        "element": {
        "clientId": 1,
          "localId": 1
      }
    },
    "sequenceNumber": 0,
      "id": {
      "clientId": 1,
        "localId": 1
    }
  });
};

