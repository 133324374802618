import WsEmulator from './WebsocketEmulator/WebsocketEmulator';

class WebSocketProxy {
    constructor(proxy, config) {
        this.ws = {};
        this.log = (message, data) => {
            console.log(`ws-proxy-em: ${message}`, { data });
        };
        this.connect = (id, config) => {
            console.log('!!!!!!! ****** WebSocketProxy connect');
            const ws = new WsEmulator();
            ws.on('open', () => {
                this.log('open[ws->bus]');
                this.proxy.send('wsopen', {
                    wsId: id,
                });
            });

            ws.on('close', (ev) => {
                console.log('**** onclose', ev);
                this.log('close[ws->bus]');
                this.proxy.off('wsopen', this.wsOpenHandler);
                this.proxy.off('wsmessage', this.wsMessageHandler);
                this.proxy.off('wsclose', this.wsCloseHandler);
                this.proxy.send('wsclose', {
                    wsId: ev.id,
                    code: ev.code,
                    reason: ev.reason,
                });
            });

            ws.on( 'message', (ev) => {
                this.log('message[ws->bus]', ev );
                this.proxy.send('wsmessage', {
                    wsId: id,
                    data: ev.data,
                });
            });

            ws.onerror = () => {
                this.log('error[ws->bus]');
                this.proxy.send('wserror', {
                    wsId: id,
                });
            };
            ws.connect(config);
            return ws;
        };
        this.getWsIdsList = () => {
            return Object.keys(this.ws);
        };

        this.getAllContent = (wsId) => {
            return this.ws[wsId].getAllContent()
        };

        this.getBuffer = (wsId) => {
            return this.ws[wsId].getBuffer()
        };

        this.getFirstWs = () => {
            return this.ws[this.getWsIdsList()[0]];
        };

        this.close = () => {
            this.getFirstWs().close({id: this.getWsIdsList()[0]});
        };

        this.proxy = proxy;
        this.wsOpenHandler = (event) => {
            console.log('***** wsopen', event);
            this.log('wsopen[bus->ws]');
            this.ws[event.wsId] = this.connect(event.wsId, config);
        };
        this.proxy.on('wsopen', this.wsOpenHandler);

        this.wsCloseHandler = (event) => {
            console.log('***** wsclose 2', event);
            this.log('close[bus->ws]');
            this.proxy.off('wsopen', this.wsOpenHandler);
            this.proxy.off('wsmessage', this.wsMessageHandler);
            this.proxy.off('wsclose', this.wsCloseHandler);
            this.ws[event.wsId].close();
            delete this.ws[event.wsId];
        };
        this.proxy.on('wsclose', this.wsCloseHandler);

        this.proxy.on('wserror', (event) => {
            this.log('error[bus->ws]');
            this.ws[event.wsId].close();
            delete this.ws[event.wsId];
        });

        this.wsMessageHandler = (event) => {
            this.log('message[bus->ws]', event.data);
            this.ws[event.wsId].send(event.data);
        };
        this.proxy.on('wsmessage', this.wsMessageHandler);
    }
}

export default WebSocketProxy;
