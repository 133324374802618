export default (constructor, overlay = false, {enforceRequiredFields, readOnly}) => {
  return [
    {
      "properties":
        {
          "group": "editor",
          "type": "scenarios",
          "onStart":
            [
              {
                "action": "toolbar.overlay.show",
                "apply": constructor ? false : overlay,
                "runOnce": true,
                "delay": 0, "params": {
                  "overlayType": readOnly ? "read_only" : "signingSession",
                  "textSets": "pdffiller"
                }
              }, {
              "action": "default_page.show",
              "apply": true,
              "runOnce": true,
              "delay": 0,
              "params": { "pageId": 0 },
              "onShow": []
            }, {
              "action": "find_form.button.show",
              "apply": false,
              "runOnce": true,
              "delay": 500,
              "onShow": [{
                "action": "find_form.bubble.show",
                "apply": true,
                "runOnce": true,
                "delay": 500
              }, { "action": "find_form.bubble.hide", "apply": true, "runOnce": true, "delay": 5500 }],
              "onClick": [{
                "action": "find_form.dialog.show",
                "apply": true,
                "runOnce": false,
                "delay": 0,
                "params": { "location": "/en/fillable-pdf-forms-search-engine.htm?q=" },
                "onSearch": [{
                  "action": "destroy.execute",
                  "apply": true,
                  "runOnce": true,
                  "delay": 0,
                  "params": { "destroyType": "save", "location": "" }
                }]
              }, { "action": "find_form.bubble.hide", "apply": true, "runOnce": true, "delay": 0 }]
            }, {
              "action": "help.show",
              "apply": false,
              "runOnce": true,
              "delay": 1000,
              "onHide": []
            }, {
              "action": "video_help.preview.show",
              "apply": false,
              "runOnce": true,
              "delay": 5000,
              "onStart": [{
                "action": "video_help.preview.hide",
                "apply": true,
                "runOnce": true,
                "delay": 0
              }, {
                "action": "video_playback.dialog.show",
                "apply": true,
                "runOnce": true,
                "delay": 0,
                "params": {
                  "autostart": true,
                  "minimizable": true,
                  "videoId": "",
                  "videoTitle": "To better understand how to use this feature watch the video",
                  "startPoint": 0,
                  "endPoint": 0
                },
                "onHide": []
              }]
            }, {
              "action": "welcome.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "params": {
                "editType": "standard",
                "documentAccess": "full",
                "fillableFieldsMode": null,
                "senderName": "",
                "recipientName": "",
                "customLogoUrl": "",
                "disclaimerUrl": "https://dev3.pdffiller.com/en/terms_of_services.htm"
              },
              "onHide": [{
                "action": "message.dialog.show",
                "apply": false,
                "runOnce": false,
                "delay": 0,
                "params": {
                  "title": "",
                  "message": "",
                  "avatarUrl": "",
                  "videoId": "",
                  "videoTitle": "To better understand how to use this feature watch the video",
                  "advancedMessage": "",
                  "businessCard": ""
                },
                "onPlay": [{
                  "action": "message.dialog.hide",
                  "apply": true,
                  "runOnce": false,
                  "delay": 0
                }, {
                  "action": "video_playback.dialog.show",
                  "apply": false,
                  "runOnce": true,
                  "delay": 0,
                  "params": {
                    "autostart": true,
                    "minimizable": true,
                    "videoId": "",
                    "videoTitle": "To better understand how to use this feature watch the video",
                    "startPoint": 0,
                    "endPoint": 0
                  },
                  "onHide": []
                }]
              }]
            }, {
              "action": "message.button.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "onClick": [{
                "action": "message.dialog.show",
                "apply": true,
                "runOnce": false,
                "delay": 0,
                "params": {
                  "title": "",
                  "message": "",
                  "avatarUrl": "",
                  "videoId": "",
                  "videoTitle": "To better understand how to use this feature watch the video",
                  "advancedMessage": "",
                  "businessCard": ""
                },
                "onPlay": [{
                  "action": "message.dialog.hide",
                  "apply": true,
                  "runOnce": false,
                  "delay": 0
                }, {
                  "action": "video_playback.dialog.show",
                  "apply": false,
                  "runOnce": true,
                  "delay": 0,
                  "params": {
                    "autostart": true,
                    "minimizable": true,
                    "videoId": "",
                    "videoTitle": "To better understand how to use this feature watch the video",
                    "startPoint": 0,
                    "endPoint": 0
                  },
                  "onHide": []
                }]
              }]
            }, {
              "action": "message.dialog.show",
              "apply": false,
              "runOnce": false,
              "delay": 0,
              "params": {
                "title": "",
                "message": "",
                "avatarUrl": "",
                "videoId": "",
                "videoTitle": "To better understand how to use this feature watch the video",
                "advancedMessage": "",
                "businessCard": ""
              },
              "onPlay": [{
                "action": "message.dialog.hide",
                "apply": true,
                "runOnce": false,
                "delay": 0
              }, {
                "action": "video_playback.dialog.show",
                "apply": false,
                "runOnce": true,
                "delay": 0,
                "params": {
                  "autostart": true,
                  "minimizable": true,
                  "videoId": "",
                  "videoTitle": "To better understand how to use this feature watch the video",
                  "startPoint": 0,
                  "endPoint": 0
                },
                "onHide": []
              }]
            }, {
              "action": "decline.button.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "onClick": [{
                "action": "decline.dialog.show",
                "apply": true,
                "runOnce": false,
                "delay": 0,
                "params": { "clearDataFlag": false, "message": "", "senderName": "", "recipientName": "" },
                "onDecline": [{
                  "action": "destroy.execute",
                  "apply": true,
                  "runOnce": true,
                  "delay": 0,
                  "params": { "destroyType": "decline", "location": "", "clearDataFlag": true, "message": "" }
                }],
                "onCancel": []
              }]
            }, {
              "action": "draft.button.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "onClick": [{
                "action": "destroy.execute",
                "apply": true,
                "runOnce": true,
                "delay": 0,
                "params": { "destroyType": "draft", "location": "https://dev3.pdffiller.com/en/forms.htm" }
              }]
            }, { "action": "constructor.show", "apply": !!constructor, "runOnce": true, "delay": 0 }, {
              "action": "images.show",
              "apply": false,
              "runOnce": false,
              "delay": 0
            }, { "action": "rearrange.show", "apply": false, "runOnce": false, "delay": 0 }, {
              "action": "signatures.show",
              "apply": false,
              "runOnce": false,
              "delay": 0
            }, {
              "action": "autofill.dialog.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "params": { "fromProject": null }
            }, {
              "action": "fakePdfEdit.button.appearance",
              "apply": true,
              "runOnce": true,
              "delay": 1000,
              "params": {
                "active": true,
                "initialHintDuration": 3000,
                "showInitialHint": false,
                "showRolloverHint": true
              }
            }, {
              "action": "preview.show",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "onExit": [{
                "action": "destroy.execute",
                "apply": true,
                "runOnce": true,
                "delay": 0,
                "params": {
                  "destroyType": "save",
                  "location": "javascript:history.back()",
                  "clearDataFlag": true,
                  "message": ""
                }
              }]
            }],
          "onConstructorExit": [{
            "action": "destroy.execute",
            "apply": !!constructor,
            "runOnce": true,
            "delay": 250,
            "params": { "destroyType": "save", "location": "https://dev3.pdffiller.com/en/forms.htm" }
          }],
          "onDone": [{
            "action": "required.dialog.show",
            "apply": enforceRequiredFields,
            "runOnce": false,
            "delay": 0,
            "condition": "IF_REQUIRED_FIELDS_STILL_EMPTY",
            "params": { "editType": enforceRequiredFields ? "l2f" : "standard", "enforceRequiredFields": enforceRequiredFields, "senderName": "", "recipientName": "" },
            "onSave": [{
              "action": "destroy.execute",
              "apply": enforceRequiredFields,
              "runOnce": true,
              "delay": 0,
              "params": { "destroyType": "save", "location": "" }
            }],
            "onSkip": [{
              "action": "destroy.execute",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "params": { "destroyType": "resend", "photoStream": null, "location": "/" }
            }, {
              "action": "finalization.dialog.show",
              "apply": enforceRequiredFields,
              "runOnce": false,
              "delay": 0,
              "params": { "editType": enforceRequiredFields ? "l2f" : "standard", "senderName": "", "recipientName": "" },
              "onSave": [{
                "action": "destroy.execute",
                "apply": true,
                "runOnce": true,
                "delay": 0,
                "params": { "destroyType": "save", "location": "" }
              }],
              "onFinish": [{
                "action": "photo_auth.dialog.show",
                "apply": false,
                "runOnce": false,
                "delay": 0,
                "condition": "IF_CAMERA_AVAILABLE",
                "params": { "photoStream": null, "senderName": "", "recipientName": "" },
                "onSkip": [{
                  "action": "destroy.execute",
                  "apply": true,
                  "runOnce": true,
                  "delay": 0,
                  "params": { "destroyType": "finalize", "photoStream": null, "location": "" }
                }],
                "onPhoto": [{
                  "action": "destroy.execute",
                  "apply": true,
                  "runOnce": true,
                  "delay": 0,
                  "params": { "destroyType": "finalize", "photoStream": null, "location": "" }
                }]
              }]
            }]
          }, {
            "action": "required.dialog.show",
            "apply": false,
            "runOnce": false,
            "delay": 0,
            "condition": "IF_REQUIRED_FIELDS_STILL_EMPTY",
            "params": { "editType": "standard", "enforceRequiredFields": true, "senderName": "", "recipientName": "" },
            "onSave": [{
              "action": "destroy.execute",
              "apply": true,
              "runOnce": true,
              "delay": 0,
              "params": { "destroyType": "draft", "location": "https://dev3.pdffiller.com/en/forms.htm" }
            }],
            "onSkip": [{
              "action": "destroy.execute",
              "apply": false,
              "runOnce": true,
              "delay": 0,
              "params": { "destroyType": "save", "location": "https://dev3.pdffiller.com/en/forms.htm" }
            }]
          }]
        }, "actionTime": 1581687564568
    }, {
      "properties": { "group": "document", "type": "resolution", "pdfDPI": 72, "contentDPI": 72, "subType": "" },
      "actionTime": 1581687566563
    }, {
      "properties": { "group": "editor", "type": "mode", "allowed": true, "subType": "main" },
      "actionTime": 1581687566569
    }
  ]
};

