export default (file, options = { removeMIME: true }) => new Promise(
  (resolve, reject) => {
    const reader = new FileReader();
    const { removeMIME } = options;
    const errorHandler = err => reject(err);

    reader.onerror = errorHandler;
    reader.onabort = errorHandler;
    reader.onload = () => {
      resolve({
        name: file.name,
        type: file.name.split('.').pop(),
        content: removeMIME
          ? reader.result.split(',')[1]
          : reader.result.split(','),
      });
    };
    reader.readAsDataURL(file);
  },
);
