import { API_URL } from "constants/config";

// const getApiUrl = () => {
//   return API_URL;
// };
const getApiUrl = () => {
  console.log('**** process.env.API_URL 2', process.env.API_URL);
  return process.env.API_URL;
};

export default getApiUrl;
