const appearanceToOptions = (appearance = {}, options = {}, { enforceRequiredFields = false }) => {

  // done button
  const aDoneButton = Object.assign({}, {
    visible: true,
    label: 'DONE',
    dropDown: [],
  }, appearance.doneButton);
  const oDoneButton = {
    main: { label: aDoneButton.label, visible: aDoneButton.visible },
    list: aDoneButton.dropDown
  };
  options.doneButton = Object.assign({}, oDoneButton, options.doneButton);

  ///////// enforceRequiredFields
  if (enforceRequiredFields) {
    options.doneButton.main.location = "javascript:runScenario('onDone')";
  }

  // logo
  const aLogo = Object.assign({}, {
      visible: true,
      url: 'https://static-ak.pdffiller.com/components/global-ui/g-logo/img/svg/logo-pdffiller-new.svg'
  }, appearance.logo);
  const oLogo = {
    urls: {
      large: aLogo.url,
      small: aLogo.url
    },
    visible: aLogo.visible
  };
  options.logo = Object.assign({}, oLogo, options.logo);

  // tools // todo rewrite in websocketEmulator
  const toolsMap = {
    'text': 'tools.text.none',
    'signature': 'tools.signature.*',
    'initials': 'tools.initials.*',
    'image': 'tools.image.none',
    'date': 'tools.text.date',
    'x': 'tools.checkmark.x',
    'v': 'tools.checkmark.v',
    'o': 'tools.checkmark.o',
    'textbox': 'tools.textbox.none',
    'sticky': 'tools.sticky.none',
    'erase': 'tools.erase.none',
    'highlight': 'tools.highlight.none',
    'blackout': 'tools.blackout.none',
    'arrow': 'tools.arrow.none',
    'line': 'tools.line.none',
    'pen': 'tools.pen.none',
    'rearrange': 'tools.pages',
    'fakeEdit': 'tools.fakePdfEdit',
    'wizard': 'settings.wizard',
    'help': 'common.help',
    'search': 'common.search',
    'pagesPanel': 'common.thumbnails',
    'addFillableFields': 'tools.constructor',
    'addWatermark': 'attributes.watermark',
  };
  let tools = [];
  if (appearance.tools) { tools = [...tools, ...appearance.tools]; }
  if (appearance.options) tools = [...tools, ...appearance.options];
  if (appearance.advancedOptions) tools = [...tools, ...appearance.advancedOptions];

  const namedTools = tools.map((tool, index) => {
    for (const property in tool) {
      if (toolsMap[property]) {
        tool['name'] = toolsMap[property];
        tool['visible'] = tool[property];
      }
    }
    return tool;
  });
  const tTools = tools.reduce((acc, tool) => {
    return Object.assign(acc, tool);
  }, {});
  const aTools = Object.keys(tTools).reduce((acc, toolName) => {
    if (toolsMap[toolName] === undefined) {
      return acc;
    }
    acc[toolsMap[toolName]] = { visible: tTools[toolName]};
    return acc;
  }, {});
  options.tools = Object.assign({}, aTools, options.tools);
  options.namedTools = namedTools;
  console.log('!!!!!! *** options', options);
  return options;
};

export default appearanceToOptions;
