import getApiUrl from './getApiUrl';

const login = (clientId, clientSecret, sessionId = '') => fetch(`${getApiUrl()}/login`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({ clientId, clientSecret, hostname: window.location.hostname, sessionId })
});

export default login;

