import { readAsLookup } from 'json-api-reader/src';
import { getApiUrl } from "../constants/config";

const prepareDoc = (clientId, token, sessionId = '') => data => fetch(`${getApiUrl()}/document/fromUrl`,
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ clientId, sessionId, ...data })
  })
  .then(response => response.json())
.then(readAsLookup);

export default prepareDoc;
