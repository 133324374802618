import EventEmitter from 'events';

class Emitter extends EventEmitter {
  editorState = '';

  state = (type, payload = {}) => {
    this.editorState = type;
    console.log('* !!!!!!! editor state:', this.editorState);
    this.emit('state', { type: type, ...payload });
  };

  userActed = (type, payload = {}) => {
    console.log('* event:', { type: type, ...payload });
    this.emit('event', {type: type, ...payload });
  };

  error = (type, payload = {}) => {
    console.log('* error:', { type: type, ...payload });
    this.emit('error', { type: type, ...payload });
  };
}

export { Emitter };
