// import _chain  from 'lodash/chain';

const normalizePages = (pages) => {
  // console.log('*** _chain(pages)', _chain(pages));
  console.log ('** paGES', pages);
  const oldPages = pages
    .filter(({ blankOf, duplicateOf }) => blankOf === -1 && duplicateOf === -1)
    .sort((a,b) => a.source - b.source)
    .map(
      ({ visible, source }) =>
        visible
          ? { source: 0, rotation: 0, range: source } // add page as is
          : { source: -1, rotation: 0, range: source, paperSize: 0 }, // add blank page
    );
  const newPages = pages
    .filter(({ blankOf, duplicateOf }) => blankOf > -1 || duplicateOf > -1)
    .sort((a,b) => a.source - b.source)
    .map(
      ({ blankOf, duplicateOf }) =>
        blankOf > -1
          ? { source: -1, rotation: 0, range: blankOf, paperSize: 0 } // add blank page
          : { source: 0, rotation: 0, range: duplicateOf }, // copy page
    );
  console.log('*** oldPages, newPages', oldPages, newPages, [...oldPages, ...newPages]);
  return [...oldPages, ...newPages];
};

export default normalizePages;
