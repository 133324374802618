const demoWatermarkOperation = {
  "id": {
    "clientId": 0,
    "localId": 4
  },
  "properties": {
    "type": "attributes",
    "subType": "watermark",
    "element": { "clientId": 0, "localId": 3 },
    "content": {
      "text": "%20PDFfiller%0ASDK%20DEMO",
      "visible": true,
      "owner": 1,
      "align":"MC",
      "alpha":0.5,
      "bold":true,
      "fontColor":"0F0F0F",
      "fontFamily":"Arial",
      "fontSize":76,
      "italic":false,
      "pagesRange":"-1",
      "rotation":315,
    },
    "group": "tools",
    "allowed": true,
    "initial": true
  },
  "confirmed": true
};

export default demoWatermarkOperation;
