// /document/autofields/
// import { readAsLookup } from 'json-api-reader/src';

import { getApiUrl } from "../constants/config";

const autoFields = (data, token) => fetch(
  `${getApiUrl()}/document/autofields/${data.documentId}`,
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ ...data })
  }).then(response => response.json());
// .then(readAsLookup);

export default autoFields;
