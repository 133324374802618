import getApiUrl from "./getApiUrl";

const getPdf = (data, token) => fetch(`${getApiUrl()}/document/getPdf`,
  {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ ...data })
  }).then(response => response.blob());

export default getPdf;
