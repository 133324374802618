const fontRecognition = (data) => {
  return [{
    "confirmed": true,
    "properties": {
      "font": data.font.font,
      "probability": data.font.probability,
      "textColor": data.font.textColor,
      "bgColor": data.font.bgColor,
      "lineHeight": data.font.lineHeight,
      "baseHeight": data.font.baseHeight,
      "ascenderHeight": data.font.ascenderHeight,
      "descenderHeight": data.font.descenderHeight,
      "baseLine": data.font.baseLine,
      "group": "font",
      "type": "recognize",
      "initial": false,
      "enabled": true,
    }
  }]
};
export default fontRecognition;
