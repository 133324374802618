export default ({ constructor = true,
                  watermark = true,
                  options
}) => {
  let doneLabel = "DONE";
  let locationDB = "done";
  let visiableDB = true;
  if (options && options.doneButton && options.doneButton.main && options.doneButton.main.label) {
    doneLabel = options.doneButton.main.label;
    locationDB = options.doneButton.main.location;
    visiableDB = options.doneButton.main.visible;
  }
  const signature = options.namedTools.filter(item => item.name === 'tools.signature.*')[0] || {};
  const initials = options.namedTools.filter(item => item.name === 'tools.initials.*')[0] || {};
  const image = options.namedTools.filter(item => item.name === 'tools.image.*')[0] || {};

  const setOrDefault = (obj, key, def = false) => {
    if (typeof obj === "undefined") return def;
    return (typeof obj[key] === "undefined" || obj[key] === null) ? def : obj[key];
  };

  return ({
    "properties": {
      "group": "editor",
      "type": "features",
      "toolbar": [
        {
          "id": "tools.selection.none",
          "label": "Selection",
          "visible": true
        },
        { "id": "tools.text.none", "label": "Text", "visible": true },
        {
          "id": "tools.signature.*", "label": "Sign", "visible": false
        },
        {
          "id": "tools.initials.*", "label": "Initials", "visible": false
        },
        {
          "id": "tools.text.date",
          "label": "Date",
          "visible": true
        },
        {
          "id": "tools.checkmark.x",
          "label": "Cross",
          "visible": true
        }, { "id": "tools.checkmark.v", "label": "Check", "visible": true }, {
          "id": "tools.checkmark.o",
          "label": "Circle",
          "visible": true
        },
        { "id": "tools.image.none", "label": "Image", "visible": false },
        { "id": "tools.textbox.none", "label": "Text Box", "visible": true },
        { "id": "tools.sticky.none", "label": "Sticky", "visible": true },
        {
          "id": "tools.erase.none",
          "label": "Erase",
          "visible": true
        }, { "id": "tools.highlight.none", "label": "Highlight", "visible": true }, {
          "id": "tools.blackout.none",
          "label": "Blackout",
          "visible": true
        }, {
          "id": "tools.arrow.none",
          "label": "Arrow",
          "visible": true
        }, { "id": "tools.line.none", "label": "Line", "visible": true }, {
          "id": "tools.pen.none",
          "label": "Draw",
          "visible": true
        }],
      "extrasbar": [
        { "id": "tools.template", "label": "Constructor", "visible": constructor },
        {
          "id": "versions",
          "label": "Versions",
          "visible": false
        }, { "id": "tools.attributes", "label": "Watermark", "visible": watermark }
        ],
      "misc": [
        { "id": "tools.pages", "label": "Rearrange", "visible": false },
        {
          "id": "tools.comments",
          "label": "Comments",
          "visible": false
        },
        { "id": "common.thumbnails", "label": "Thumbnails", "visible": true },
        {
          "id": "common.help",
          "label": "Help",
          "visible": true
        }, { "id": "common.feedback", "label": "Feedback", "visible": false },
        {
          "id": "signature_wizard.options",
          "label": "",
          "type": setOrDefault(signature.options, 'type', true),
          "draw": setOrDefault(signature.options, 'draw', true),
          "upload": setOrDefault(signature.options, 'upload', false),
          "capture": setOrDefault(signature.options, 'capture', false),
          "qr": setOrDefault(signature.options, 'qr', null),
          "sms": setOrDefault(signature.options, 'sms', null),
          "email": setOrDefault(signature.options, 'email', null),
          "notarize": false,
          "notarizeLocation": false,
          "visible": true
        },{
          "id": "initials_wizard.options",
          "label": "",
          "type": setOrDefault(initials.options, 'type', true),
          "draw": setOrDefault(initials.options, 'draw', true),
          "upload": setOrDefault(initials.options, 'upload', false),
          "capture": setOrDefault(initials.options, 'capture', false),
          "qr": setOrDefault(initials.options, 'qr', null),
          "sms": setOrDefault(initials.options, 'sms', null),
          "email": setOrDefault(initials.options, 'email', null),
          "notarize": false,
          "notarizeLocation": false,
          "visible": true
        },{
          "id": "image_wizard.options",
          "label": "",
          "type": setOrDefault(image.options, 'type', true),
          "draw": setOrDefault(image.options, 'draw', true),
          "upload": setOrDefault(image.options, 'upload', false),
          "capture": setOrDefault(image.options, 'capture', false),
          "qr": setOrDefault(image.options, 'qr', null),
          "sms": setOrDefault(image.options, 'sms', null),
          "email": setOrDefault(image.options, 'email', null),
          "notarize": false,
          "notarizeLocation": false,
          "visible": true
        },
        { "id": "common.search", "label": "Search", "visible": false }, {
          "id": "tools.fakePdfEdit",
          "label": "",
          "visible": true
        }],
      "modebar": [{
        "id": "mode.main",
        "label": "Edit",
        "visible": false,
        "selected": false
      }, {
        "id": "mode.constructor",
        "label": "Add Fields",
        "visible": false,
        "selected": false
      }, { "id": "mode.preview", "label": "Preview", "visible": false, "selected": false }],
      "constructor": [
        { "id": "control.breadcrumbs", "visible": true, "label": null }, {
        "id": "button.preview",
        "label": "Preview",
        "visible": true
      }, { "id": "button.cancel", "label": "Cancel", "visible": true }, {
        "id": "button.save",
        "label": "SAVE",
        "visible": true
      }
      ],
      "preview": [
        { "id": "control.breadcrumbs", "visible": true, "label": null }, {
        "id": "button.exit_preview",
        "label": "Preview",
        "visible": true
      }],
      "planPermissions": [
        { "id": "tools.text.none", "allowed": true }, {
        "id": "tools.checkmark.x",
        "allowed": true
      }, { "id": "tools.checkmark.v", "allowed": true }, {
        "id": "tools.checkmark.o",
        "allowed": true
      }, { "id": "tools.text.date", "allowed": true }, { "id": "tools.erase", "allowed": true }, {
        "id": "tools.blackout",
        "allowed": true
      }, { "id": "tools.highlight", "allowed": true }, { "id": "tools.textbox", "allowed": true }, {
        "id": "tools.arrow",
        "allowed": true
      }, { "id": "tools.line", "allowed": true }, { "id": "tools.pen", "allowed": true }, {
        "id": "tools.sticky",
        "allowed": true
      }, { "id": "signatures.add", "allowed": false, "freeLimit": 3 }, {
        "id": "images.add",
        "allowed": false
      }, { "id": "versions.apply", "allowed": true }, {
        "id": "tools.*.signature.stamp",
        "allowed": true
      }, { "id": "tools.pages", "allowed": true }, {
        "id": "tools.attributes.*",
        "allowed": true
      }, { "id": "tools.template", "allowed": true }],
      "logo": {
        "urls": {
          "large": "https://static_dev81.pdffillers.com/img/header/logo-pdffiller.svg",
          "small": "https://static_dev81.pdffillers.com/img/header/logo-pdffiller-small.svg"
        }, "visible": true
      },
      "doneButton": {
        "main": {
          "id": "done.choice",
          "label": doneLabel,
          "location": locationDB
        },
        "list": options.doneButton ? options.doneButton.list : [],
        "visible": visiableDB
      },
      "enabled": true
    }, "actionTime": 1581519580552
  });
}
