import normalizePages from "../operations/normalizePages";
import rearrange from "../api/rearrange";
import rearrangeStatus from "../api/rearrangeStatus";
import getSourceOperation from '../mock/source';

const pingStatus = async ({documentId}, token) => {
  const statusResult = await rearrangeStatus({documentId}, token);
  console.log('* statusResult', statusResult);
  if (statusResult.status === 'COMPLETED') {
    return statusResult.urlToFlatDocument;
  }
  if (statusResult.status === 'PENDING') {
    return await pingStatus({documentId}, token);
  }
};

const rearrangePages = async ({ item, token, documentId, urlToFlatDocument }) => {
  console.log('*** item.properties.pages 222', item.properties.pages);
  if ( typeof item.properties.pages[0]['duplicateOf'] === 'undefined') {
    console.log('*** first pages', item.properties.pages);
    return [item];
  }
  const pagesOperation = { ...item };
  const pages = normalizePages(item.properties.pages);
  const urlToFlatDocument2 = await rearrange({ pages: pages, documentId, urlToFlatDocument }, token).then(response => {
    console.log('** response', response);
    return pingStatus({documentId}, token);
  });
  // const urlToFlatDocument2 = await rearrange({ pages: item.properties.pages, documentId, urlToFlatDocument }, token);
  console.log('*   urlToFlatDocument2', urlToFlatDocument2);
  // return [item];
  // return
  const source = getSource(urlToFlatDocument2);
  pagesOperation.confirmed = true;
  // pagesOperation.properties.allowed = true;
  pagesOperation.properties.initial = false;
  console.log('** source', [source, pagesOperation], pagesOperation === item);
  return [source, pagesOperation];
  // const operations = await rearrange({ item, token, documentId });
  // return operations;
};


const getSource = (url, content = null) => {
  console.log('** !getSourceOperation', url);
  let version = 1;
  return ({
    "properties": {
      "pdf": {
        "status": "FINISHED",
        "url": url,
        "errorLocation": "https://www.pdffiller.com/en/error-pdf-converting/146367973.htm",
        "errorCode": 0,
        "version": ++version,
        "defaultPage": 0,
        "pagesModified": false
      },
      "group": "document",
      "type": "source",
      "enabled": true
    }
  });
};

export default rearrangePages;
